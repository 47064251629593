import React from "react";
const SliderSquareIcons = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={99} height={24} fill="none">
    <rect
      width={30.001}
      height={23}
      x={0.249}
      y={0.5}
      fill="#fff"
      fillOpacity={0.01}
      rx={1}
    />
    <path fill="#D8E6FD" d="M.249 10.5h30v3h-30z" />
    <rect
      width={30.001}
      height={23}
      x={34.249}
      y={0.5}
      fill="#fff"
      fillOpacity={0.01}
      rx={1}
    />
    <path fill="#3A83F6" d="M34.25 10.5h30v3h-30z" />
    <rect
      width={30.001}
      height={23}
      x={68.251}
      y={0.5}
      fill="#fff"
      fillOpacity={0.01}
      rx={1}
    />
    <path fill="#D8E6FD" d="M68.25 10.5h30.002v3H68.25z" />
  </svg>
);
export default SliderSquareIcons;
