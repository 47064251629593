import React from "react"
const VerticalLineIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={2}
    height={30}
    fill="none"
  >
    <path stroke="currentColor" strokeLinecap="round" d="M.75.5v29" />
  </svg>
)
export default VerticalLineIcon;
