import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageNode } from "../../../types/PageNode";
import { RootState } from "../../../store";
import { NodeRenderService } from "../../../service/NodeRenderService";
import Node from "../node";
import CustomStyles from "../../../service/CustomsStyles";
import { customIconsSVG } from "../../EditModal/components/custom-icons";
import { CustomStyleField } from "../../../types/CustomStyleField";
import { NAVIGATION_ICON_DEFAULT } from "../../../data/constants";

interface InputProps {
  node: PageNode;
  handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleDoubleClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  styleObject: any;
  getInnerValue(nodeToShow: PageNode): string | React.JSX.Element;
}

interface IOpenCloseIconProps {
  id: string;
  iconKey: string | undefined;
}

export const AccordionElement: React.FC<InputProps> = ({
  node,
  handleClick,
  handleDoubleClick,
  styleObject,
  getInnerValue,
}) => {
  let nodeToShow: PageNode = { ...node };
  const isAdvancedMode = useSelector(
    (state: RootState) => state.appStorage.isAdvancedMode
  );
  const isPreviewState = useSelector(
    (state: RootState) => state.appStorage.preview
  );
  const isMobileViewDimension = useSelector(
    (state: RootState) => state.appStorage.isMobileViewDimension
  );
  const activeNode = useSelector((state: RootState) => state.nodeModal.value);
  const activeContainerNode = useSelector(
    (state: RootState) => state.activeContainerNode.activeContainerNode
  );

  const [isExpanded, setIsExpanded] = useState(nodeToShow.expanded);
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  let className = NodeRenderService.getClasses(
    nodeToShow,
    activeNode,
    activeContainerNode,
    isPreviewState,
    isAdvancedMode
  ).join(" ");

  useEffect(() => {
    const handleShown = () => handleShownBody(true);
    const handleHidden = () => handleShownBody(false);

    const handleShownBody = (isShown: boolean) => {
      setIsExpanded(isShown);
    };

    const element = ref.current;
    if (element && element.closest("#mainBlock")) {
      element.addEventListener("shown.bs.collapse", handleShown);
      element.addEventListener("hidden.bs.collapse", handleHidden);
    }

    return () => {
      if (element) {
        element.removeEventListener("shown.bs.collapse", handleShown);
        element.removeEventListener("hidden.bs.collapse", handleHidden);
      }
    };
  }, [node, dispatch]);

  useEffect(() => {
    if (!isPreviewState) return;
    setIsExpanded(nodeToShow.expanded);
  }, [isPreviewState, nodeToShow.expanded]);

  const OpenCloseIcon: React.FC<IOpenCloseIconProps> = ({ id, iconKey }) => {
    const svgContent = customIconsSVG[iconKey || ""];

    return svgContent ? (
      <div
        style={iconsStyles}
        id={id}
        dangerouslySetInnerHTML={{ __html: svgContent }}
      />
    ) : (
      <div
        style={iconsStyles}
        id={id}
        className={`w-100 bi ${iconKey ? iconKey : ""}`}
      />
    );
  };

  const iconSize = nodeToShow?.iconStyles?.[CustomStyleField.FontSize]
    ? `${nodeToShow.iconStyles[CustomStyleField.FontSize]}px`
    : `${NAVIGATION_ICON_DEFAULT.ACCORDION_ICON_SIZE}px`;

  const iconsStyles = Object.fromEntries(
    Object.entries(
      NodeRenderService.getIconStyles(nodeToShow.iconStyles) || {}
    ).filter(([key]) => key !== "font-size")
  );

  return (
    <>
      <style>
        {`
        #blockContent${nodeToShow.id} .accordion-button {
          background-color: transparent;
          padding: 0;
          padding-right: 16px;
        }

        #blockContent${nodeToShow.id} .accordion-button:focus {
          box-shadow: none;
        }

        #blockContent${nodeToShow.id} .accordion-body {
          padding: 0;
        }

        #blockContent${nodeToShow.id} .accordion-button:not(.collapsed) {
          background-color: transparent;
           color: initial;
           box-shadow: none;
        }

       #blockContent${nodeToShow.id} .accordion-button::after,
         #blockContent${nodeToShow.id} .accordion-button:not(.collapsed)::after {
           background-image: none;
           width: 0;
         }
           #blockContent${nodeToShow.id} .accordion-button {
          padding-right: 0;
        }

        #blockContent${nodeToShow.id} .accordion-button.collapsed #openIcon,
        #blockContent${nodeToShow.id} .accordion-button:not(.collapsed) #closeIcon {
          width: 100%;
          height: 100%;
          font-size: ${iconSize};
        }

        #blockContent${nodeToShow.id} .accordion-button:not(.collapsed) #openIcon,
        #blockContent${nodeToShow.id} .accordion-button.collapsed #closeIcon {
          width: 0;
          height: 0;
          font-size: 0;
        }
      `}
      </style>

      <div
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        key={nodeToShow.id}
        id={"blockContent" + nodeToShow.id}
        className={className}
        style={styleObject}
        ref={ref}
      >
        <>
          <h2
            className={`${nodeToShow.nodes[0].className}`}
            id={`heading${nodeToShow.nodes[0].id}`}
          >
            <button
              className={`accordion-button${isExpanded ? "" : " collapsed"}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${nodeToShow.nodes[0].id}`}
              aria-expanded={isExpanded}
              aria-controls={`collapse${nodeToShow.nodes[0].id}`}
            >
              {nodeToShow.nodes[0].nodes.map((node: PageNode) => (
                <Node
                  isPreviewState={isPreviewState}
                  key={node.id}
                  node={node}
                />
              ))}
              {getInnerValue(nodeToShow.nodes[0])}
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  [nodeToShow?.iconPlace || "right"]: "15px",
                  transform: "translateY(-50%)",
                }}
              >
                <OpenCloseIcon
                  id="closeIcon"
                  iconKey={nodeToShow.accordionIconClose}
                />
                <OpenCloseIcon
                  id="openIcon"
                  iconKey={nodeToShow.accordionIconOpen}
                />
              </div>
            </button>
          </h2>
          <div
            id={`collapse${nodeToShow.nodes[0].id}`}
            className={`accordion-collapse collapse${
              isExpanded ? " show" : ""
            }`}
            aria-labelledby={`heading${nodeToShow.nodes[0].id}`}
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
          >
            <div className={nodeToShow.nodes[1].className}>
              {nodeToShow.nodes[1].nodes.map((node: PageNode) => (
                <Node
                  isPreviewState={isPreviewState}
                  key={node.id}
                  node={node}
                />
              ))}
              {getInnerValue(nodeToShow.nodes[1])}
            </div>
          </div>
        </>
      </div>
      <CustomStyles node={node} isMobileViewDimension={isMobileViewDimension} />
    </>
  );
};
