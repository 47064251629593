import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    addNodeToActiveNode,
    addNodeToActiveNodeWithAutoSelect,
    getDefaultDivColNode,
    getNewNode,
    makeStructureChange
} from "../../../service/NodeService";
import {findNodeWhichAreContainer} from "../../../service/EditBarService";
import containerImg from "../../../assets/element-img/container.png"
import btnImg from "../../../assets/element-img/button.png"
import iframeImg from "../../../assets/element-img/iframe.png"
import blockImg from "../../../assets/element-img/img.png"
import textImg from "../../../assets/element-img/text.png"
import accordionImg from "../../../assets/element-img/accordion.png"
import sliderImg from "../../../assets/element-img/slider.png"
import {Modal} from "bootstrap";
import {hideAddElementModal} from "../../../service/ModalService";
import StaticTags from "../add-element/advanced/StaticTags";
import {changeActiveModalNode} from "../../../reducers/node-modal";
import {NodeType, PageNode} from "../../../types/PageNode";
import {RootState} from "../../../store";
import {changeNewNode} from "../../../reducers/app";
import iconBlock from "../../../assets/element-img/icon-block.png"
import DivAccordionNodeFactory from '../../../service/Node/Factory/DivAccordionNodeFactory';
import DivSliderNodeFactory from '../../../service/Node/Factory/DivSliderNodeFactory';
import { ActiveTab, setActiveTab } from '../../../reducers/active-tab';

const ElementAddTab = () => {
    const activeNode: PageNode = useSelector((state: RootState) => state.nodeModal.value);
    const isAdvancedMode = useSelector((state: RootState) => state.appStorage.isAdvancedMode);
    const dispatch = useDispatch();
    const nodes = useSelector((state: RootState) => state.nodes.present.value);
    if (!activeNode) {
        return <></>
    }

    return (
            <div className="p-3 text-dark-gray">
                <div className='row g-3'>
                    <div className='col-6 col-lg-4'>
                        <div className="btn p-0 element-block"
                            onClick={() => {
                                if (activeNode.type === NodeType.DivRowNode) {
                                    let nodeContainer = findNodeWhichAreContainer(activeNode, nodes[0], 'row');
                                    if (nodeContainer.structure && nodeContainer.structure <= 3) {
                                        const newNode = {
                                            ...nodeContainer,
                                            structure: nodeContainer.structure + 1
                                        }
                                        const currentNode = makeStructureChange(nodeContainer, newNode)
                                        dispatch(changeActiveModalNode(currentNode))
                                    } else {
                                        const newNode = addNodeToActiveNode(dispatch, nodeContainer, getDefaultDivColNode(Date.now().toString(), 'div', 'container-col', nodeContainer.id, NodeType.DivColNode));
                                        dispatch(changeNewNode(newNode))
                                        dispatch(changeActiveModalNode(newNode))
                                    }
                                    Modal.getOrCreateInstance('#edit-block-modal').show();
                                    hideAddElementModal();
                                } else {
                                    let newNode: PageNode = getNewNode(NodeType.DivNode, activeNode)
                                    let tmpNode = {
                                        tagName: 'div',
                                    }
                                    addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {...newNode, ...tmpNode});
                                    Modal.getOrCreateInstance('#edit-block-modal').show();
                                    hideAddElementModal();
                                }
                            }}
                            data-bs-target="#edit-text-modal" data-bs-toggle="modal">
                            <img src={containerImg} alt="" className="img-fluid element-img"/>
                        </div>
                        <div className="fw-bold mt-2 element-title">Container</div>
                    </div>

                    <div className='col-6 col-lg-4'>
                        <div className="btn p-0 element-block"
                            onClick={() => {
                                let newNode: PageNode = getNewNode(NodeType.ButtonNode, activeNode)
                                let tmpNode = {
                                    tagName: 'a',
                                    disallowedAddElements: true,
                                    className: "btn",
                                }
                                addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {...newNode, ...tmpNode});
                                Modal.getOrCreateInstance('#edit-block-modal').show();
                                hideAddElementModal();
                            }}
                            data-bs-target="#edit-text-modal" data-bs-toggle="modal">
                            <img src={btnImg} alt="" className="img-fluid element-img"/>
                        </div>
                        <div className="fw-bold mt-2 element-title">Button</div>
                    </div>

                    <div className='col-6 col-lg-4'>
                        <div className="btn p-0 element-block"
                            onClick={() => {
                                let newNode: PageNode = getNewNode(NodeType.TextNode, activeNode)
                                let tmpNode = {
                                    tagName: 'text',
                                    disallowedAddElements: true,
                                    className: "",
                                }
                                addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {...newNode, ...tmpNode});
                                Modal.getOrCreateInstance('#edit-block-modal').show();
                                hideAddElementModal();
                            }}
                            data-bs-target="#edit-text-modal" data-bs-toggle="modal">
                            <img src={textImg} alt=""
                                className="img-fluid element-img"/>
                        </div>
                        <div className="fw-bold mt-2 element-title">Text</div>
                    </div>

                    <div className='col-6 col-lg-4'>
                        <div className="btn p-0 element-block"
                            onClick={() => {
                                let newNode: PageNode = getNewNode(NodeType.ImageNode, activeNode)
                                let tmpNode = {
                                    tagName: 'img',
                                    disallowedAddElements: true,
                                    className: "img",
                                }
                                addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {...newNode, ...tmpNode});
                                Modal.getOrCreateInstance('#edit-block-modal').show();
                                hideAddElementModal();
                            }}
                            data-bs-target="#edit-text-modal" data-bs-toggle="modal">
                            <img src={blockImg} alt=""
                                className="img-fluid element-img"/>
                        </div>
                        <div className="fw-bold mt-2 element-title">Graphic</div>
                    </div>

                    <div className='col-6 col-lg-4'>
                        <div className="btn p-0 element-block"
                            onClick={() => {
                                let newNode: PageNode = getNewNode(NodeType.IFrameNode, activeNode)
                                let tmpNode = {
                                    tagName: 'iframe'
                                }
                                addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {...newNode, ...tmpNode});
                                Modal.getOrCreateInstance('#edit-block-modal').show();
                                hideAddElementModal();
                            }}
                            data-bs-target="#edit-text-modal" data-bs-toggle="modal">
                            <img src={iframeImg} alt=""
                                className="img-fluid element-img"/>
                        </div>
                        <div className="fw-bold mt-2 element-title">Iframe</div>
                    </div>

                    <div className='col-6 col-lg-4'>
                        <div className="btn p-0 element-block"
                                onClick={() => {
                                    let newNode: PageNode = getNewNode(NodeType.IconNode, activeNode)
                                    let tmpNode = {
                                        tagName: 'i',
                                        className: "bi",
                                        editSettings: {
                                            isIcons: true
                                        }
                                    }
                                    addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {...newNode, ...tmpNode});
                                    Modal.getOrCreateInstance('#edit-block-modal').show();
                                    hideAddElementModal();
                                }}
                                data-bs-target="#edit-text-modal" data-bs-toggle="modal">
                            <img src={iconBlock} alt=""
                                    className="img-fluid element-img"/>
                        </div>
                        <div className="fw-bold mt-2 element-title">Icon</div>
                    </div>

                    <div className='col-6 col-lg-4'>
                        <div className="btn p-0 element-block"
                            onClick={() => {
                                let newNode: PageNode = getNewNode(NodeType.Accordion, activeNode)
                                let tmpNode = DivAccordionNodeFactory.getAccordionNode(newNode);
                                addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {...tmpNode});
                                Modal.getOrCreateInstance('#edit-block-modal').show();
                                hideAddElementModal();
                            }}
                            data-bs-target="#edit-text-modal" data-bs-toggle="modal">
                            <img src={accordionImg} alt=""
                                className="img-fluid element-img"/>
                        </div>
                        <div className="fw-bold mt-2 element-title">Accordion</div>
                    </div>

                    <div className='col-6 col-lg-4'>
                        <div className="btn p-0 element-block"
                            onClick={() => {
                                let newNode: PageNode = getNewNode(NodeType.SliderNode, activeNode)
                                let tmpNode = DivSliderNodeFactory.getSliderNode(newNode);
                                addNodeToActiveNodeWithAutoSelect(dispatch, activeNode, {...tmpNode});
                                dispatch(setActiveTab(ActiveTab.SLIDER));
                                Modal.getOrCreateInstance('#edit-block-modal').show();
                                hideAddElementModal();
                            }}
                            data-bs-target="#edit-text-modal" data-bs-toggle="modal">
                            <img src={sliderImg} alt=""
                                className="img-fluid element-img"/>
                        </div>
                        <div className="fw-bold mt-2 element-title">Slider</div>
                    </div>

                </div>
                {(isAdvancedMode) && <>
                    <h5 className="ps-2 mt-4 pb-2"><b>Advanced</b></h5>
                    <StaticTags activeNode={activeNode} dispatch={dispatch}></StaticTags>
                    {/*<DynamicTag activeNode={activeNode} dispatch={dispatch}></DynamicTag>*/}
                </>}
            </div>
    );
}

export default ElementAddTab;
