// noinspection GraphQLUnresolvedReference

import {gql} from '@apollo/client';

export const PUBLISH_PAGE = gql`
    mutation PublishPage($content:String!,$pageId:Int!){
        publishPage(content: $content, pageId: $pageId) {
            id
        }
    }
`;

export const ADD_TEMPLATE = gql`
    mutation AddTemplate($content:String!,$img:String!,$type:Int!){
        addTemplate(content: $content, img: $img, type: $type) {
            content
            img
        }
    }
`;
export const UPDATE_TEMPLATE = gql`
    mutation UpdateTemplate($id:Int!, $content:String!,$img:String!){
        updateTemplate(id:$id, content: $content, img: $img) {
            content
            img
        }
    }
`;

export const REMOVE_TEMPLATE = gql`
    mutation RemoveTemplate($id:Int!){
        removeTemplate(id: $id)
    }
    
`;
export const DUPLICATE_PAGE= gql`
    mutation DuplicatePage($id: Int!, $isMeQr: Boolean){
        duplicatePage(id: $id, isMeQr: $isMeQr) {
            title
            image
            id
            contentString
        }
    }
`;
export const DELETE_PAGE = gql`
    mutation DeletePage($id: Int!) {
        deletePage(id: $id) {
            title
            image
            id
            contentString
        }
    }
`;