import React, { useState } from "react";
import { PageNode } from "../../../../types/PageNode";
import { ChangeNodeFunction } from "../../../../service/editModalService";
import IconsSize from "./IconsSize";
import { NAVIGATION_ICON_DEFAULT } from "../../../../data/constants";
import SelectIconsColor from "./SelectIconsColor";
import { SliderDotTypeEnum } from "../../../../types/CustomElement";

export interface IIndicatorIconsStylesProps {
  editableNode: PageNode;
  changeNodeFunction: ChangeNodeFunction;
  title: string;
}

const IndicatorIconsStyles: React.FC<IIndicatorIconsStylesProps> = ({
  editableNode,
  changeNodeFunction,
  title,
}) => {
  const [showBlock, setShowBLock] = useState<string>("");

  return (
    <>
      <div className="d-flex">
        <h5 className={"mt-3 mb-3 me-auto"}>{title}</h5>
      </div>
      <div className="row g-3 mb-2">
        <div className="col-lg-6">
          <IconsSize
            changeNodeFunction={changeNodeFunction}
            editableNode={editableNode}
            iconStyleSizeOption="indicatorStyles"
            labelName="Icons Size"
            defaultValue={
              editableNode.sliderDotType === SliderDotTypeEnum.Dot
                ? NAVIGATION_ICON_DEFAULT.SLIDER_INDICATOR_SIZE_DOT
                : NAVIGATION_ICON_DEFAULT.SLIDER_INDICATOR_SIZE_SQUARE
            }
          />
        </div>
        <div className="col-lg-6">
          <SelectIconsColor
            changeNodeFunction={changeNodeFunction}
            editableNode={editableNode}
            iconStyleColorOption="indicatorStyles"
            labelName="Icons Color"
            defaultValue={NAVIGATION_ICON_DEFAULT.SLIDER_INDICATOR_COLOR}
            showBlock={showBlock}
            setShowBLock={setShowBLock}
          />
        </div>
      </div>
    </>
  );
};

export default IndicatorIconsStyles;
