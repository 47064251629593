import {createSlice} from "@reduxjs/toolkit";
import { postData, prePublishPage } from "../service/post";

let pathArray = window.location.pathname.split('/');
const pageId = pathArray[1];
export const nodes = createSlice({
    name: 'nodes',
    initialState: {
        value: [],
        errorMessage: null,
    },
    reducers: {
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        addNode: (state, action) => {
            let element = findNode(state.value[0], action.payload.activeNode.id);
            // let element = state.value.findLast((element) => element.id === action.payload.activeNode.id);
            let newNode = action.payload.newNode;
            newNode.id ??= (Date.now()).toString();
            newNode.classes ??= [];
            newNode.nodes ??= [];
            newNode.parentId = action.payload.activeNode.id;
            // newNode.classes ??= ['border-1', 'border-dashed p-4'];
            element.nodes.push(action.payload.newNode);
            // state.value = [{tagName: 'div'}];
        },
        changeNode: (state, action) => {
            changeNodeByNode(state.value[0], action.payload.editedNode);
        },
        changeNodeNoSendRequest: (state, action, sendRequest = true) => {
            const result=changeNodeByNode(state.value[0], action.payload.editedNode);
            if(!result)
            {
                console.log(action.payload.editedNode)
            }
        },
        deleteNode: (state, action) => {
            deleteNodeByNode(state.value[0], action.payload.removeNode)
        },
        moveForward: (state, action) => {
            if(action.payload.isMobile){
                moveMobileNodeForward(state.value[0], action.payload.node)
            }else{
                moveNodeForward(state.value[0], action.payload.node)
            }
        }, moveBack: (state, action) => {
            if(action.payload.isMobile){
                moveMobileNodeBack(state.value[0], action.payload.node)
            }else{
                moveNodeBack(state.value[0], action.payload.node)
            }
        },
        setNodes: (state, action) => {
            state.value = action.payload.nodes;
        },
        updateRootNode: (state, action) => {
            state.value[0] = action.payload.newRootNode;
        },
        changeNodeHidden: (state, action) => {
            const node = findNode(state.value[0], action.payload.id);
            if (node) {
                if (action.payload.type === 'hidden') {
                    node.hidden = action.payload.hidden;
                } else if (action.payload.type === 'hiddenMobile') {
                    node.hiddenMobile = action.payload.hiddenMobile;
                }
            }
        },
        returnToDesktopMovement: (state) => {
            const result = state.value[0].nodes.map((node) => {
                if(node.nodes.length) return removeGrids(node)
                    return node
            })
            // console.log(result)
            // state.value = result
        },
        returnToMobileMovement: (state) => {
            const nodes = state.value[0].nodes.map((node) => {
                if(node.nodes.length) return addGrids(node)
                    return node
            })
            state.value[0] = {...state.value[0], nodes: nodes, customStyleMobile: {...state.value[0].customStyleMobile, display: "grid"}}
        },
    }
})


/**
 *
 * @param node
 * @param id
 */
export function findNode(node, id) {
    if (node.id === id) {
        return node;
    } else if (node.nodes) {
        let foreachResult = null;
        node.nodes.forEach(function (element) {
            if (element) {
                let tmpElement = findNode(element, id);
                if (tmpElement) {
                    foreachResult = tmpElement;
                    return null;
                }
            }
        });
        return foreachResult;
    }
    return null;
}

/**
 *
 * @param node
 * @param editedNode
 */
function changeNodeByNode(node, editedNode) {
    if (node.id === editedNode.id) {
        Object.assign(node, editedNode);
        return true;
    }
    if (node.nodes.length > 0) {
        let searchIndex = false;
        node.nodes.forEach(function (element, index) {
            if (element) {
                if (element.id === editedNode.id) {
                    searchIndex = index;
                    return null;
                }
            }
        });
        if (searchIndex !== false) {
            node.nodes[searchIndex] = editedNode;
            return true;
        } else {
            node.nodes.forEach(function (element) {
                if (element) {
                    if (changeNodeByNode(element, editedNode)) {
                        return true;
                    }
                }
            });
        }
        //TODO: make it depends to foreach result
        return false;
    }
}

/**
 *
 * @param node
 * @param removeNode
 */
function deleteNodeByNode(node, removeNode) {
    if (node.nodes.length > 0) {
        let searchIndex = -1;
        node.nodes.forEach(function (element, index) {
            if (element && element.id === removeNode.id) {
                searchIndex = index;
                return null;
            }
        });
        if (searchIndex > -1) {
            node.nodes.splice(searchIndex, 1);
            return true;
        } else {
            node.nodes.forEach(function (element) {
                if (element !== null) {
                    if (deleteNodeByNode(element, removeNode))
                        return true;
                }
            });
        }
        //TODO: make it depends to foreach result
        return false;
    }
}

/**
 *
 * @param node
 * @param nodeToAction
 */

function moveNodeForward(node, nodeToAction) {
    if (node.nodes.length > 0) {
        addClassGridToParentNode(node, false)
        let searchIndex = -1;
        node.nodes.forEach(function (element, index) {
            if (element !== null && element.id === nodeToAction.id) {
                searchIndex = index;
                return null;
            }
        });
        if (searchIndex > -1 && node.nodes[searchIndex + 1] !== undefined) {
            let tmp = node.nodes[searchIndex];
            node.nodes[searchIndex] = node.nodes[searchIndex + 1];
            node.nodes[searchIndex + 1] = tmp;
            return true;
        } else if (searchIndex === node.nodes.length - 1) {
            let tmp = node.nodes[searchIndex];
            node.nodes.splice(searchIndex, 1);
            node.nodes.unshift(tmp);
            return true;
        } else {
            node.nodes.forEach(function (element) {
                if (element !== null) {
                    if (moveNodeForward(element, nodeToAction))
                        return true;
                }
            });
        }
        //TODO: make it depends to foreach result
        return false;
    }
}

function moveMobileNodeForward(node, nodeToAction) {
    if (node.nodes.length > 0) {
        const searchIndex = getNodeIndex(node.nodes, nodeToAction.id);

        if (searchIndex > -1) {
            addClassGridToParentNode(node, true)
            node.nodes.forEach(function (element, index) {
                initialOrder(element, index)
            });

            const fixedOrder = node.nodes[searchIndex].customStyleMobile.order
            const nodeBelow = findNodeByOrder(node.nodes, fixedOrder + 1)

            if(!nodeBelow) return

            node.nodes[searchIndex].customStyleMobile = { ...node.nodes[searchIndex].customStyleMobile, order: nodeBelow.customStyleMobile.order };
            nodeBelow.customStyleMobile = { ...nodeBelow.customStyleMobile, order: fixedOrder };

            return true;
        } else if (searchIndex === node.nodes.length - 1) {
            let tmp = node.nodes[searchIndex];
            node.nodes.splice(searchIndex, 1);
            node.nodes.unshift(tmp);
            return true;
        } else {
            node.nodes.forEach(function (element) {
                if (element !== null) {
                    if (moveNodeForward(element, nodeToAction))
                        return true;
                }
            });
        }
        //TODO: make it depends to foreach result
        return false;
    }
}

/**
 *
 * @param node
 * @param nodeToAction
 */
function moveNodeBack(node, nodeToAction) {
    if (node.nodes.length > 0) {
        addClassGridToParentNode(node, false)
        let searchIndex = -1;
        node.nodes.forEach(function (element, index) {
            if (element !== null && element.id === nodeToAction.id) {
                searchIndex = index;
                return null;
            }
        });
        if (searchIndex - 1 > -1) {
            let tmp = node.nodes[searchIndex];
            node.nodes[searchIndex] = node.nodes[searchIndex - 1];
            node.nodes[searchIndex - 1] = tmp;
            return true;
        } else {
            node.nodes.forEach(function (element) {
                if (element !== null) {
                    if (moveNodeBack(element, nodeToAction))
                        return true;
                }
            });
        }
        //TODO: make it depends to foreach result
        return false;
    }
}


function moveMobileNodeBack(node, nodeToAction) {
    if (node.nodes.length > 0) {
        addClassGridToParentNode(node, true)
        const searchIndex = getNodeIndex(node.nodes, nodeToAction.id);

        if (searchIndex > -1) {
            node.nodes.forEach(function (element, index) {
                initialOrder(element, index)
            });

            const fixedOrder = node.nodes[searchIndex].customStyleMobile.order
            const nodeAbove = findNodeByOrder(node.nodes, fixedOrder - 1)
    
            if(!nodeAbove) return

            node.nodes[searchIndex].customStyleMobile = { ...node.nodes[searchIndex].customStyleMobile, order: nodeAbove.customStyleMobile.order };
            nodeAbove.customStyleMobile = { ...nodeAbove.customStyleMobile, order: fixedOrder };

            return true;
        } else {
            node.nodes.forEach(function (element) {
                if (element !== null) {
                    if (moveNodeBack(element, nodeToAction))
                        return true;
                }
            });
        }
        //TODO: make it depends to foreach result
        return false;
    }
}

function initialOrder(node, index){
    if(!node) return;
    if (!node.customStyleMobile?.order) {
        node.customStyleMobile = { ...node.customStyleMobile, order: index }
    }
}

function findNodeByOrder(nodes, order){
    if(!nodes.length) return
    const foundNode = nodes.find((element) => element.customStyleMobile?.order === order);

    if (foundNode) {
        return foundNode;
    }
}

function removeGrids(node){
    if(!node.nodes.length) return node
    addClassGridToParentNode(node, false)
    node.nodes.forEach((node) => {
        if(node.nodes.length){
            return removeGrids(node)
        }
    })
}

function addGrids(node){
    if(!node.nodes.length) return node;
    addClassGridToParentNode(node, true)
    node.nodes.forEach((node) => {
        if(node.nodes.length){
            return addGrids(node)
        }
    })
    return node
}

function addClassGridToParentNode(node, isMobile){
    if(!node.nodes.length) return

    if(node?.custom?.display === "flex"){
        node.customStyleMobile = {...node.customStyleMobile, display: isMobile ? "grid" : "flex", gridAutoFlow: isMobile ? "row" : ""}
    }else{
        node.customStyleMobile = {...node?.customStyleMobile, display: isMobile ? "grid" : ""}
    }
}

function getNodeIndex (nodes, nodeId){
    return nodes.findIndex((element) => element !== null && element.id === nodeId) ?? -1;
}


export const {addNode} = nodes.actions
export const {changeNode} = nodes.actions
export const {changeNodeNoSendRequest} = nodes.actions
export const {deleteNode} = nodes.actions
export const {moveForward} = nodes.actions
export const {moveBack} = nodes.actions
export const {setNodes} = nodes.actions
export const {updateRootNode} = nodes.actions;
export const {changeNodeHidden} = nodes.actions
export const {setErrorMessage} = nodes.actions
export const {returnToDesktopMovement} = nodes.actions
export const {returnToMobileMovement} = nodes.actions



export default nodes.reducer;

export const nodesMiddleware = (store) => (next) => async (action) => {
    const result = next(action);
    const state = store.getState().nodes;
    const queryString = window.location.search;
    const urlSearchParams = new URLSearchParams(queryString);
    const token = urlSearchParams.get("token");

    if (
        action.type === addNode.type ||
        action.type === changeNode.type ||
        action.type === deleteNode.type ||
        action.type === moveForward.type ||
        action.type === moveBack.type ||
        action.type === updateRootNode.type ||
        action.type === changeNodeHidden.type
    ) {
        try {
            if (token) {
              await postData(
                process.env.REACT_APP_BACKEND_ADDRESS + "/page/save/" + pageId,
                state.present.value
              );
            } else {
              setTimeout(async () => {
                await prePublishPage(+pageId, state.present.value);
              }, 500);
            }
        } catch (error) {
            store.dispatch(setErrorMessage(error.message));
        }
    }

    return result;
};