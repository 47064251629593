import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const GlobalWidgets: React.FC = () => {
  const activeModal = useSelector((state: RootState) => state.modal.activeModal)
  const publishModal = useSelector((state:RootState) => state.modal.publishModal)

  useEffect(() => {
    const findWidget = () => {
      const iframe = document.querySelector('iframe[name="helpcrunch-iframe"]') as HTMLElement;
      if (!iframe) return;
      iframe.style.bottom = activeModal === null && !publishModal.isActive ? "0px" : "-100%";
    };

    findWidget()

  }, [activeModal,publishModal.isActive]);

  useEffect(() => {
    if (window.self !== window.top) return;
    // Add HelpCrunch Chat widget
    window.helpcrunchSettings = {
      organization: "meteam",
      appId: "a49db63f-f5cd-4baa-b7dc-2691e28744c5",
    };

    const helpCrunchScript = document.createElement("script");
    helpCrunchScript.type = "text/javascript";
    helpCrunchScript.innerHTML = `
        (function(w,d){
          var hS = w.helpcrunchSettings;
          if (!hS || !hS.organization) {
            return;
          }
          var widgetSrc = 'https://embed.helpcrunch.com/sdk.js';
          w.HelpCrunch = function() {
            w.HelpCrunch.q.push(arguments);
          };
          w.HelpCrunch.q = [];
          
          function r() {
            if (d.querySelector('script[src="' + widgetSrc + '"]')) {
              return;
            }
            var s = d.createElement('script');
            s.async = 1;
            s.type = 'text/javascript';
            s.src = widgetSrc;
            (d.body || d.head).appendChild(s);
          }

          if (d.readyState === 'complete' || hS.loadImmediately) {
            r();
          } else if (w.attachEvent) {
            w.attachEvent('onload', r);
          } else {
            w.addEventListener('load', r, false);
          }
        })(window, document);
      `;

    document.body.appendChild(helpCrunchScript);

    return () => {
      document.body.removeChild(helpCrunchScript);
    };
  }, []);

  return null;
};

export default GlobalWidgets;
