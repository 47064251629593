import React, { ChangeEvent } from "react";
import { ChangeNodeFunction } from "../../../../service/editModalService";
import { CustomStyleField } from "../../../../types/CustomStyleField";
import { IconPlaceEnum, PageNode } from "../../../../types/PageNode";
interface IIconPlaceSelectProps {
  editableNode: PageNode;
  changeNodeFunction: ChangeNodeFunction;
}

const IconPlaceSelect: React.FC<IIconPlaceSelectProps> = ({
  editableNode,
  changeNodeFunction,
}) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newEditableNode = structuredClone(editableNode);

    newEditableNode.iconPlace = event.target.value as IconPlaceEnum;
    newEditableNode.nodes[0] = { ...newEditableNode.nodes[0] };
    newEditableNode.nodes[0].nodes = [...newEditableNode.nodes[0].nodes];
    newEditableNode.nodes[0].nodes[0] = {
      ...newEditableNode.nodes[0].nodes[0],
    };
    newEditableNode.nodes[0].nodes[0].customStyle = {
      ...newEditableNode.nodes[0].nodes[0].customStyle,
    };

    newEditableNode.nodes[0].nodes[0].isMixedPadding = true;
    newEditableNode.nodes[0].nodes[0].customStyle = {
      ...newEditableNode.nodes[0].nodes[0].customStyle,
      [CustomStyleField.PaddingTop]: "16px",
      [CustomStyleField.PaddingBottom]: "16px",
      [CustomStyleField.PaddingLeft]:
        event.target.value === IconPlaceEnum.Left ? "50px" : "16px",
      [CustomStyleField.PaddingRight]:
        event.target.value === IconPlaceEnum.Right ? "50px" : "16px",
    };

    changeNodeFunction(newEditableNode);
  };

  return (
    <>
      <div className="fw-bold fs-8 mb-2">Accordion Icon Place</div>
      <div className="form-floating small">
        <select
          className="form-select border-border-gray"
          onChange={handleChange}
          value={editableNode.iconPlace}
        >
          <option value={IconPlaceEnum.Right}>Right of the title</option>
          <option value={IconPlaceEnum.Left}>Left of the title</option>
        </select>
        <label htmlFor="edit-block-modal-font-size" className="form-label">
          Icon Place
        </label>
      </div>
    </>
  );
};

export default IconPlaceSelect;
