import React, {useEffect, useRef, useState} from "react";
import {Editor} from "@tinymce/tinymce-react";
import {ChangeNodeFunction,} from "../../../service/editModalService";
import {MobileStyleRadioButton} from "../ui/MobileStyleRadioButton";
import {PageNode} from "../../../types/PageNode";
import {UserRole} from "../../../types/User";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import ColorPicker from "react-best-gradient-color-picker";
import { extractInlineStylesToClasses, mergeInlineStylesWithClasses, parseHTMLString, updateElementClasses } from "../helpers";
import { fontFamilyFormats} from "../../../data/fonts";

interface TinyTextInputProperty {
    editableNode: PageNode,
    inline?: boolean,
    menubar?: boolean,
    isMobileViewDimension: boolean,
    changeNodeFunction: ChangeNodeFunction,
}
 
export const TinyTextInput: React.FC<TinyTextInputProperty> = ({
                                                                   editableNode,
                                                                   changeNodeFunction,
                                                                   inline = false,
                                                                   menubar = true,
                                                                   isMobileViewDimension,
                                                               }) => {
    // ЭТУ БАЛДУ НЕЛЬЗЯ РЕРЕНДЕРИТЬ ТАК КАК СБИВАЕТСЯ КУРСОР ПОСЛЕ НАЖАТИЯ ЕНТЕР!!!!
    const editorRef = useRef(null);

    const user = useSelector((state: RootState) => state.appStorage.user)
    const mainNode: PageNode = useSelector((state: RootState) => state.nodes.present.value[0]);

    const [editorKey, setEditorKey] = useState(Date.now());
    const nodeModalValue: PageNode = useSelector((state: RootState) => state.nodeModal.value);
    const [textValue, setTextValue] = useState("");
    const [gradient, setGradient] = useState("");
    const [isActiveGradient, setIsActiveGradient] = useState(false);

    let isAdmin: boolean = false;
    if (user) {
        isAdmin = user.roles.includes(UserRole.ROLE_ADMIN)
    }
    

    useEffect(() => {
        setEditorKey(Date.now());
        return () => {
            setIsActiveGradient(false);
        }
    }, [mainNode, editableNode.isMobileTextEnabled, editableNode.textClasses, editableNode.textMobileClasses]);
    
    useEffect(() => {
        document.addEventListener("focusin", function (e: FocusEvent) {
            const target = e.target as HTMLElement
            if (
                target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !==
                null
            ) {
                e.stopImmediatePropagation();
            }
        });
    }, []);

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            const button = document.querySelector('button[title="Reveal or hide additional toolbar items"]') as HTMLButtonElement;
            if (button && !button.contains(event.target as Node) && button.getAttribute('aria-expanded') === 'true') {
                // Close popup "..." if click outside button
                button.click();
            }
        };

        document.addEventListener("click", handleDocumentClick);

        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    let useMobileText: boolean = false
    let isShowMobileTextEnabler = false
    if (isMobileViewDimension && isAdmin) {
        //TODO: tmp fix make, Common user at me qr see this.
        isShowMobileTextEnabler = true
        useMobileText = editableNode.isMobileTextEnabled ?? false
    }

    const updateValues = () => {
        const editorContent = editorRef.current.getContent({ format: "html" });

        if (useMobileText) {
            const { updatedHTML: newHTMLText, styleMap: additionalStyles } = 
                updateElementClasses(editorContent, editableNode.textMobileClasses);
            const doc = parseHTMLString(newHTMLText);
            const { updatedHTML, styleMap } = 
                mergeInlineStylesWithClasses(doc, {...editableNode.textMobileClasses, ...additionalStyles});

            editableNode.textMobileClasses = styleMap;
            editableNode.textClasses =  { ...(editableNode.textClasses || {}) };
            editableNode.value = updatedHTML;
            editableNode.isMobileTextEnabled = true;
        } else {
            const { updatedHTML: newHTMLText, styleMap: additionalStyles } = 
                updateElementClasses(editorContent, editableNode.textClasses);
            const doc = parseHTMLString(newHTMLText);
            const { updatedHTML, styleMap } = 
                mergeInlineStylesWithClasses(doc, {...editableNode.textClasses, ...additionalStyles});

            editableNode.textClasses = styleMap;
            editableNode.textMobileClasses = { ...(editableNode.textMobileClasses || {}) };
            editableNode.value = updatedHTML;
            editableNode.isMobileTextEnabled = false;
        }

        changeNodeFunction(editableNode);
    };

    // extract inline styles to desktop and mobile classes
    useEffect(() => {
        const doc = parseHTMLString(editableNode.value || "");
        const { styleMap, updatedHTML } = extractInlineStylesToClasses(doc);

        setTextValue(updatedHTML);

        if (useMobileText && editableNode.textMobileClasses && Object.keys(editableNode.textMobileClasses).length > 0) {
            editableNode.textMobileClasses = {
                ...(editableNode.textMobileClasses || nodeModalValue.textMobileClasses || {}),
                ...styleMap,
            };
        } else {
            editableNode.textClasses = {
                ...(editableNode.textClasses || nodeModalValue.textClasses || {}),
                ...styleMap,
            };
        }  
    }, [useMobileText, editableNode, nodeModalValue]);


    // if (value === undefined)
    //     value = ''

    //TODO: https://www.tiny.cloud/docs/tinymce/latest/react-pm-host/
    
    const handleChangeColor = (color: string): void => {
        setGradient(color);
    };
  
    const elements = document.querySelectorAll(".tox-collection__item-label")
    if(elements){
        elements.forEach((elem) => elem.removeAttribute("style"))
    }

    const toggleGradientPicker = () => {
        setIsActiveGradient(true);
        if(window.self !== window.top || isMobileViewDimension) {
            const button = document.querySelector<HTMLButtonElement>('button[aria-label="Reveal or hide additional toolbar items"]');
            button?.click();
            button?.blur();
        }
      };

    const applyGradientColor = () => {
        if(!gradient) return;
        if (editorRef.current) {
            const editor = editorRef.current;
            const content = editor.selection.getContent({ format: "html" });
            const selectedNode = editor.selection.getNode();
            const existingStyles = selectedNode?.getAttribute("style") || "";

            if (gradient.startsWith("linear-gradient") || gradient.startsWith("radial-gradient")) {
                // Apply gradient as a background with clip text
                const gradientStyle = `background: ${gradient}; -webkit-background-clip: text; color: transparent;`;
                const combinedStyles = `${existingStyles.length ? `${existingStyles}; ` : "" }${gradientStyle}`.trim();
                const wrappedContent = `<span style="${combinedStyles}">${content}</span>`;
                editor.execCommand("mceInsertContent", false, wrappedContent);
            } else {
                // Apply solid color
                editor.execCommand("forecolor", false, gradient);
            }
        }
        setIsActiveGradient(false)
    };

    const handleCopy = (event: any) => {
        event.preventDefault();
        let content = editorRef.current.selection.getContent({ format: "html" });
        if (!content.length) {
            content = editorRef.current.getContent({ format: "html" });
        }

        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = content;
        
        const elements = tempDiv.querySelectorAll('*');
        
        elements.forEach((element: HTMLElement) => {
            element.classList.forEach((className) => {
            if (editableNode.textClasses[className]) {
                element.style.cssText += editableNode.textClasses[className];
                element.classList.remove(className);
            }
            });
        });

        // Add marker for editor text identification
        const marker = `<meta name="editor-origin" content="constructor-editor-me-page">`;
        const updatedContent = marker + tempDiv.innerHTML;
        const textContent = tempDiv.textContent || '';
        if (navigator.clipboard && navigator.clipboard.write) {
            navigator.clipboard.write(  [
                new ClipboardItem({
                    "text/html": new Blob([updatedContent], { type: "text/html" }),
                    "text/plain": new Blob([textContent], { type: "text/plain" }),
                }),
            ]).catch((err) => {
                console.error("Clipboard write failed: ", err);
            });
        }
    };

    const handlePaste = (event: any) => {
        const htmlContent = event.clipboardData.getData("text/html");
        // Check if the copied content is not from the editor
        if (!htmlContent.includes('name="editor-origin" content="constructor-editor-me-page"')) {
            return;
        }

        event.preventDefault();
        const cleanedContent = htmlContent.replace(/<meta name="editor-origin" content="constructor-editor-me-page">/, "");
        if (cleanedContent) {
            editorRef.current.selection.setContent(cleanedContent);
        } else {
            const plainText = event.clipboardData.getData("text/plain");
            editorRef.current.selection.setContent(plainText);
        }
    };

    return (
        <>
            <div className="mb-3">
                <Editor
                    key={editorKey}
                    tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                    licenseKey="gpl"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={
                        textValue
                    }
                    init={{
                        height: 500,
                        menubar: menubar,
                        inline: inline,
                        mobile: {
                            menubar: menubar,
                            toolbar_mode: 'sliding',  
                        },
                        // forced_root_block_attrs: {
                        //     'class': 'p-0 m-0',
                        // },
                        // selector: selector,
                        font_size_formats:
                            "8px 10px 12px 14px 16px 18px 20px 22px 24px 28px 30px 32px 34px 36px 40px 42px 44px 46px 48px 56px",
                        line_height_formats: "1 1.1 1.2 1.3 1.4 1.5 1.6 1.8 2",
                        font_family_formats: fontFamilyFormats.join(';'),
                        plugins: [
                            "advlist",
                            "fullscreen",
                            "lists",
                            "link",
                            "table",
                            "wordcount",
                        ],
                        toolbar:
                            "undo redo | formatselect | " +
                            "bold italic underline | forecolor | gradientPicker | fontselect fontsize |  alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | link|" +
                            "removeformat | help",
                        content_style: `
                            body{
                                background-color: #CDD1D7;
                                    span{
                                        ${mainNode?.customStyle?.color ? `color: ${mainNode.customStyle.color};` : ""}
                                    }
                            }
                            ${editableNode.textClasses ? Object.entries(editableNode.textClasses).map(([key, value]) => {
                                return `.${key}{
                                        ${value}
                                    }`
                            }).join('') : ""}
                             ${editableNode.textMobileClasses && isShowMobileTextEnabler && useMobileText ? Object.entries(editableNode.textMobileClasses).map(([key, value]) => {
                                return `.${key}{
                                        ${value}
                                    }`
                            }).join('') : ""}
                        `,
                        setup: (editor) => {
                            editor.on('init', function () {
                                const editedText = editor.getBody().querySelector("p")
                                const text = editor.getBody().querySelector("span")
                                if(editedText?.style) editedText.style.fontFamily = mainNode?.customStyle?.fontFamily;
                                if(text?.style && !text?.style?.fontFamily) text.style.fontFamily = mainNode?.customStyle?.fontFamily;

                            });
                            editor.on('PostRender', () => {
                                const menubar = editor.getContainer().querySelector('.tox-menubar') as HTMLElement;
                                if (menubar) {
                                    menubar.style.flexWrap = 'nowrap';
                                    menubar.style.overflow = 'auto';

                                    const styleSheet = document.createElement('style');
                                    styleSheet.type = 'text/css';
                                    styleSheet.innerText = `
                                        .tox-menubar {
                                            -ms-overflow-style: none;
                                            scrollbar-width: none;
                                        }
                                        .tox-menubar::-webkit-scrollbar {
                                            display: none;
                                        }
                                    `;
                                    document.head.appendChild(styleSheet);
                                }
                            });    
                            editor.ui.registry.addButton("gradientPicker", {
                              text: "Color",
                              onAction: () => {
                                toggleGradientPicker();
                              },
                            });
                            editor.on("Copy", (e) => {
                                handleCopy(e);
                            });
                            editor.on("Paste", (e) => {
                                handlePaste(e);
                            });
                        },
                    }}
                    onBlur={() => {
                        if(isActiveGradient) return;
                        updateValues();
                    }}
                />
                {isActiveGradient && (
                    <div className="position-absolute shadow bg-white rounded" style={{zIndex: 1030, top: "0", right: "30px", padding: "20px" }}>
                            <div className="mb-3 d-flex align-items-center justify-content-between">
                                <div>
                                    <button type="button" className="btn btn-close pt-3" onClick={() => {
                                        setIsActiveGradient(false);
                                    }}></button>
                                </div>
                            </div>
                        <ColorPicker value={gradient} onChange={handleChangeColor}/>
                        <div className="text-end">
                            <button type="button" className="btn btn-primary w-100 mt-3" onClick={applyGradientColor}>Select</button>
                        </div>
                    </div>
                )}
                
            </div>
            <div className={'d-flex'}>

                {isShowMobileTextEnabler ? <><h5 className={'mb-3 me-auto'}>Save to mobile version</h5>
                    <MobileStyleRadioButton currentStatus={editableNode.isMobileTextEnabled ?? false}
                                            onChange={(state) => {
                                                editableNode.textClasses = { ...(nodeModalValue.textClasses || {}) };
                                                editableNode.textMobileClasses = { ...(nodeModalValue.textMobileClasses || {}) };
                                                editableNode.isMobileTextEnabled = state;
                                                changeNodeFunction(editableNode)
                                            }}/></> : ""}

            </div>
        </>
    );
};

export default TinyTextInput;
