import React from "react";
import { ChangeNodeFunction } from "../../../../service/editModalService";
import { PageNode } from "../../../../types/PageNode";
import DoubleIconButton from "../SliderSettingsTab/DoubleIconButton";

interface IAccordionIconProps {
  changeNodeFunction: ChangeNodeFunction;
  editableNode: PageNode;
}

const ICONS_SLIDER = [
  ["bi-chevron-down", "bi-chevron-up"],
  ["bi-chevron-double-down", "bi-chevron-double-up"],
  ["bi-caret-down-fill", "bi-caret-up-fill"],
  ["bi-caret-down", "bi-caret-up"],
  ["bi-arrow-down-circle", "bi-arrow-up-circle"],
  ["bi-arrow-down-circle-fill", "bi-arrow-up-circle-fill"],
  ["bi-chevron-down", "bi-chevron-right"],
  ["bi-chevron-double-down", "bi-chevron-double-right"],
  ["bi-caret-down-fill", "bi-caret-right-fill"],
  ["bi-caret-down", "bi-caret-right"],
  ["bi-arrow-down-circle", "bi-arrow-right-circle"],
  ["bi-arrow-down-circle-fill", "bi-arrow-right-circle-fill"],
];

const AccordionIcon: React.FC<IAccordionIconProps> = ({
  editableNode,
  changeNodeFunction,
}) => {
  return (
    <>
      <div className="fw-bold fs-8 mb-2">Accordion Icon</div>
      <div className="row g-3">
        {ICONS_SLIDER.map((icons, index) => (
          <div className="col-6 col-sm-4 col-lg-2" key={index}>
            <DoubleIconButton
              icons={icons}
              property1="accordionIconOpen"
              property2="accordionIconClose"
              editableNode={editableNode}
              changeNodeFunction={changeNodeFunction}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default AccordionIcon;
