import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeIsPublishPageState, changePreview, changePublishStatus, setInitPublishPage} from "../../reducers/app";
import {GetPageDocument} from "../../service/Page/PageService.hooks";
import { usePublishPageMutation } from "../preview/publishPage/publishPage.hooks";
import { setPublishModal } from "../../reducers/modalSlice";

function PublishPageState() {
    const dispatch = useDispatch();
    const pageId = useSelector((state) => state.appStorage.pageId)
    const initPublishPage = useSelector((state) => state.appStorage.initPublishPage)
    const [publishPage] = usePublishPageMutation();

    const tryPublishPage = async(blockContent) => {
        dispatch(changePublishStatus({isLoading: true, isCompleted: false}))
        try{
            const {data} = await publishPage({
                variables: {
                    content: blockContent.innerHTML.toString(),
                    pageId: parseInt(pageId)
                },
                ...(initPublishPage === false && { 
                    refetchQueries: [{ query: GetPageDocument, variables: { id: pageId } }]
                })
            })
            if(data?.publishPage && window.self === window.top) dispatch(setPublishModal({isActive: true, pageId: pageId}))
            dispatch(changePublishStatus({isLoading: false, isCompleted: true}))
            setTimeout(() => {
                dispatch(changePublishStatus({isLoading: false, isCompleted: false}))
            }, 5000)
        }catch(error){
            console.error(error)
            dispatch(changePublishStatus({isLoading: false, isCompleted: false}))
        }
    }

    useEffect(() => {

        const blockContent = document.getElementById("mainBlock")
        if (blockContent !== null) {
            // delay for building HTML elements before publishing
            setTimeout(() => {
                tryPublishPage(blockContent)

                dispatch(changePreview(false))
                dispatch(changeIsPublishPageState(false))
            }, 0);
        } else {
        }
    });
}

export default PublishPageState;