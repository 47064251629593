import React, { useState } from "react";
import { PageNode } from "../../../../types/PageNode";
import { ChangeNodeFunction } from "../../../../service/editModalService";
import { NAVIGATION_ICON_DEFAULT } from "../../../../data/constants";
import IconsSize from "../SliderSlyleTab/IconsSize";
import SelectIconsColor from "../SliderSlyleTab/SelectIconsColor";

export interface IAccordionIconStylesProps {
  editableNode: PageNode;
  changeNodeFunction: ChangeNodeFunction;
  title: string;
}

const AccordionIconStyles: React.FC<IAccordionIconStylesProps> = ({
  editableNode,
  changeNodeFunction,
  title,
}) => {
  const [showBlock, setShowBLock] = useState<string>("");

  return (
    <>
      <div className="d-flex">
        <h5 className={"mt-3 mb-3 me-auto"}>{title}</h5>
      </div>
      <div className="row g-3 mb-2">
        <div className="col-lg-6">
          <IconsSize
            changeNodeFunction={changeNodeFunction}
            editableNode={editableNode}
            iconStyleSizeOption="iconStyles"
            labelName="Icons Size"
            defaultValue={NAVIGATION_ICON_DEFAULT.ACCORDION_ICON_SIZE}
          />
        </div>
        <div className="col-lg-6">
          <SelectIconsColor
            changeNodeFunction={changeNodeFunction}
            editableNode={editableNode}
            iconStyleColorOption="iconStyles"
            labelName="Icons Color"
            defaultValue={NAVIGATION_ICON_DEFAULT.ACCORDION_ICON_COLOR}
            showBlock={showBlock}
            setShowBLock={setShowBLock}
          />
        </div>
      </div>
    </>
  );
};

export default AccordionIconStyles;
