import React from "react";
import { ChangeNodeFunction } from "../../../../service/editModalService";
import { PageNode } from "../../../../types/PageNode";

interface ISliderAutoscrollProps {
  changeNodeFunction: ChangeNodeFunction;
  editableNode: PageNode;
}

const SliderAutoscroll: React.FC<ISliderAutoscrollProps> = ({
  editableNode,
  changeNodeFunction,
}) => {
  return (
    <>
      <div className="form-check form-switch my-3 ">
        <input
          className="form-check-input fs-8"
          type="checkbox"
          role="switch"
          id="isAutoscrollSwitch"
          checked={editableNode.isAutoscroll}
          onChange={() =>
            changeNodeFunction({
              ...editableNode,
              isAutoscroll: !editableNode.isAutoscroll,
            })
          }
        />
        <label
          className="form-check-label fw-bold fs-8"
          htmlFor="isAutoscrollSwitch"
        >
          Autoscroll <span className="fw-normal text-secondary">(will appear in mode preview)</span>
        </label>
      </div>
      <div className="col-lg-3">
        <div className={`position-relative text-background-mobile form-floating`}>
          <p className="position-absolute end-0 top-50 translate-middle text-background-mobile me-3 pe-1 pt-2">
            <small>sec</small>
          </p>
          <input
            id="autoscrollTime"
            min="0"
            type="number"
            placeholder="Slide interval"
            className="form-control"
            value={editableNode.autoscrollTime || ""}
            onChange={(e) => {
              const value = e.target.value;
              changeNodeFunction({
                ...editableNode,
                autoscrollTime: value === "" ? 0 : Number(value),
              });
            }}
          />
          <label htmlFor="autoscrollTime" className={`form-label `}>
            Slide interval
          </label>
        </div>
      </div>
    </>
  );
};

export default SliderAutoscroll;
