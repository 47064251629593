import React from "react";
const SliderDotsIcons = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={81} height={16} fill="none">
    <path fill="#DDE0E4" d="M8.75 16a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z" />
    <path fill="#3E4957" d="M40.75 16a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z" />
    <path fill="#DDE0E4" d="M72.75 16a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z" />
  </svg>
);

export default SliderDotsIcons;
