import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNodes } from "../../reducers/nodes";
import FileManager, { calculateTotalSize } from "../../service/FileManager/FileManager";
import { FileManagerFile } from "../../service/FileManager/FileManagerFile";
import { useGetPageLazyQuery } from "../../service/Page/PageService.hooks";
import { RootState } from "../../store";
import { UserRole } from "../../types/User";
import {
    GetUserFilesDocument,
    useDeleteFileMutation,
    useGetUserFilesQuery,
    useUploadFileMutation
} from "./FileUploadComponent.hooks";

interface InputProperties {
    allowedFileTypes?: string[]
    onFileChoose?: (file: FileManagerFile) => void;
    onClose: () => void;
}

const FileUploadComponent: React.FC<InputProperties> = ({allowedFileTypes, onFileChoose, onClose}) => {
    const user = useSelector((state: RootState) => state.appStorage.user);
    const pageId = useSelector((state: RootState) => state.appStorage.pageId);
    const isAdmin = user ? user.roles.includes(UserRole.ROLE_ADMIN) : false;
    const dispatch = useDispatch();
    // const [isInitialised, setIsInitialised] = useState(true)
    const [files, setFiles] = useState<FileManagerFile[]>([]);
    const [pendingRequests, setPendingRequests] = useState(0);
    const [isFullStorage, setIsFullStorage] = useState(false);

    const [uploadFileMutation, {loading: fileUploadLoading}] = useUploadFileMutation({});
    const [deleteFileMutation] = useDeleteFileMutation({});
    const {data, loading, refetch} = useGetUserFilesQuery({
        // skip: !isInitialised,
        variables: {},
        fetchPolicy: 'network-only',
    });
    const [getPage] = useGetPageLazyQuery({
        variables: {id: pageId}, 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            const contentString = data.page.contentString;
            if (contentString && JSON.parse(contentString).length > 0) {
                dispatch(setNodes({nodes: JSON.parse(contentString)}));
            }
        },
    })

    const onFileUpload = (file: File) => {
        const currentSizeBytes = calculateTotalSize(files);
        if ((file.size + currentSizeBytes > 100*1024*1024) && !data?.user.activePremium && !isAdmin) {
            setIsFullStorage(true);
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
            try {
                const {data} = await uploadFileMutation({
                    variables: {file: reader.result as string, fileName: file.name},
                    refetchQueries: [{
                        query: GetUserFilesDocument,
                        fetchPolicy: 'network-only'
                    }]
                });
                console.log(data.uploadFile);
            } catch (error) {
                console.error('Error uploading file', error);
            }
        };
    }
    const onFileDelete = async (file: FileManagerFile) => {
        setFiles(files => files.filter(el => el.id !== file.id));
        setPendingRequests(prev => prev + 1); 
        await deleteFileMutation({variables: {id: file.id}})
        setPendingRequests(prev => prev - 1);
    }

    function getFileExtension(url: string) {
        // Use the lastIndexOf method to find the position of the last dot in the URL
        const dotIndex = url.lastIndexOf('.');

        // Check if the dot is found and is not at the end of the string
        if (dotIndex !== -1 && dotIndex < url.length - 1) {
            // Return the substring from the dot to the end of the string
            return url.substring(dotIndex + 1);
        } else {
            // Return an empty string if no valid extension is found
            return '';
        }
    }

    useEffect(() => {
        if (!loading && data) {
            const updatedFiles = data.user.files.map(file => ({
                id: file.id,
                serverPath: `${process.env.REACT_APP_IMAGE_ADDRESS}/constructor/user/${data.user.id}/${file.filePath}`,
                extension: getFileExtension(file.filePath),
                title: file.fileName,
                size: file.size
            }));
            setFiles(updatedFiles);
        }
    }, [data, loading])

    useEffect(() => {
        if (pendingRequests === 0) {
            refetch();
            getPage();
        }
    }, [pendingRequests, refetch, getPage])

    return <>
        {/*{openButton()}*/}
        <FileManager onModalClose={onClose} onFileChoose={onFileChoose} onFileDelete={onFileDelete} files={files}
                     allowedFileTypes={allowedFileTypes}
                     fileUploadLoading={fileUploadLoading}
                     onFileUpload={onFileUpload} isFullStorage={isFullStorage} isUserPremium={!!data?.user.activePremium}/></>
}
export default FileUploadComponent;