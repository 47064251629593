import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { PageNode } from "../../../types/PageNode";
import { RootState } from "../../../store";
import { NodeRenderService } from "../../../service/NodeRenderService";
import Node from "../node";
import CustomStyles from "../../../service/CustomsStyles";
import { SliderDotTypeEnum } from "../../../types/CustomElement";
import { Carousel } from "bootstrap";
import { CustomStyleField } from "../../../types/CustomStyleField";
import { NAVIGATION_ICON_DEFAULT } from "../../../data/constants";

interface InputProps {
  node: PageNode;
  handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleDoubleClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  styleObject: any;
  getInnerValue(nodeToShow: PageNode): string | React.JSX.Element;
}

export const SliderElement: React.FC<InputProps> = ({
  node,
  handleClick,
  handleDoubleClick,
  styleObject,
  getInnerValue,
}) => {
  let nodeToShow: PageNode = { ...node };
  const isAdvancedMode = useSelector(
    (state: RootState) => state.appStorage.isAdvancedMode
  );
  const isPreviewState = useSelector(
    (state: RootState) => state.appStorage.preview
  );
  const isMobileViewDimension = useSelector(
    (state: RootState) => state.appStorage.isMobileViewDimension
  );
  const activeNode = useSelector((state: RootState) => state.nodeModal.value);
  const activeContainerNode = useSelector(
    (state: RootState) => state.activeContainerNode.activeContainerNode
  );

  let className = NodeRenderService.getClasses(
    nodeToShow,
    activeNode,
    activeContainerNode,
    isPreviewState,
    isAdvancedMode
  ).join(" ");

  const getBorder = () => {
    let borderStyle = "";
    if (!(nodeToShow.isHidden && !isAdvancedMode) && nodeToShow.id !== "1") {
      if (activeNode && nodeToShow.id === activeNode.id) {
        borderStyle = "solid-blue";
      }
    }
    return borderStyle;
  };

  const sliderElementsBorderStyle = isPreviewState
    ? ""
    : ` border-dashed border-1 border-primary rounded ${getBorder()}`;
  const isAddAutoscroll =
    isPreviewState && nodeToShow.isAutoscroll && nodeToShow.autoscrollTime > 0;

  useEffect(() => {
    const carouselElement = document.querySelector(
      `#blockContent${nodeToShow.id}`
    );
    if (!(carouselElement instanceof HTMLElement)) {
      console.error("Invalid carouselElement", carouselElement);
      return;
    }
    if (!carouselElement) return;

    const existingCarousel = Carousel.getInstance(carouselElement);
    if (existingCarousel) {
      existingCarousel.dispose();
    }

    if (isAddAutoscroll) {
      new Carousel(carouselElement, {
        interval: nodeToShow.autoscrollTime * 1000,
        touch: true,
      });
    }
  }, [nodeToShow.id, nodeToShow.autoscrollTime, isAddAutoscroll]);

  const iconsStyles = NodeRenderService.getIconStyles(nodeToShow.iconStyles);

  const dotFontSize =
    nodeToShow?.indicatorStyles?.[CustomStyleField.FontSize] ||
    (nodeToShow.sliderDotType === SliderDotTypeEnum.Dot
      ? NAVIGATION_ICON_DEFAULT.SLIDER_INDICATOR_SIZE_DOT
      : NAVIGATION_ICON_DEFAULT.SLIDER_INDICATOR_SIZE_SQUARE);
  const dotBackground =
    nodeToShow?.indicatorStyles?.[CustomStyleField.Color] ||
    NAVIGATION_ICON_DEFAULT.SLIDER_INDICATOR_COLOR;

  const commonDotStyles = {
    width: dotFontSize,
    height:
      nodeToShow.sliderDotType === SliderDotTypeEnum.Dot
        ? dotFontSize
        : Math.max(1, Math.round(dotFontSize / 10)),
    background: dotBackground,
    border: 0,
    marginLeft: 6,
    marginRight: 6,
  };

  const customDotStyles =
    nodeToShow.sliderDotType === SliderDotTypeEnum.Dot
      ? {
          borderRadius: "50%",
        }
      : {};

  if (isPreviewState && !nodeToShow.nodes.length) {
    return null;
  }

  return (
    <>
      <style>
        {`
          @media (max-width: 576px) {
            #blockContent${nodeToShow.id} .carousel-controls {
              opacity: 0;
            }
          }
        `}
      </style>

      <script>
        {`
          document.addEventListener('DOMContentLoaded', function () {
            const carouselElement = document.querySelector('#blockContent${
              nodeToShow.id
            }');
            if (carouselElement) {
              const carousel = new bootstrap.Carousel(carouselElement, {
                interval: ${
                  isAddAutoscroll ? nodeToShow.autoscrollTime * 1000 : false
                },
                touch: true 
              });
            }
          });
        `}
      </script>

      <div
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        key={nodeToShow.id}
        id={"blockContent" + nodeToShow.id}
        style={styleObject}
        className={`carousel slide ${className}`}
        data-bs-touch="true"
        {...(isAddAutoscroll ? { "data-bs-ride": "carousel" } : {})}
      >
        {nodeToShow.isShowSliderDots ? (
          <div
            className={`carousel-indicators py-2${sliderElementsBorderStyle}`}
          >
            {nodeToShow.nodes.map((node: PageNode, index: number) => (
              <button
                key={node.id}
                type="button"
                data-bs-target={`#blockContent${nodeToShow.id}`}
                data-bs-slide-to={`${index}`}
                className={`${index === 0 ? "active" : undefined}`}
                aria-current={index === 0 ? "true" : undefined}
                aria-label={`Slide ${index + 1}`}
                style={{
                  ...commonDotStyles,
                  ...customDotStyles,
                }}
              ></button>
            ))}
          </div>
        ) : null}
        <div className="carousel-inner">
          {nodeToShow.nodes.map((node: PageNode, index: number) => (
            <div
              className={`carousel-item${index === 0 ? " active" : ""}`}
              key={node.id}
            >
              <Node isPreviewState={isPreviewState} node={node} />
            </div>
          ))}
          {nodeToShow.nodes.length === 0 && !isPreviewState && (
            <div
              className={`carousel-item active`}
              style={{ background: "rgb(230, 230, 230)", height: "200px" }}
            >
              {getInnerValue(nodeToShow)}
            </div>
          )}
        </div>
        <div className="carousel-controls">
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target={`#blockContent${nodeToShow.id}`}
            data-bs-slide="prev"
          >
            <span className={`${sliderElementsBorderStyle}`} aria-hidden="true">
              {nodeToShow.isShowSliderIcons ? (
                <i
                  className={`bi ${nodeToShow.sliderLeftIcon}`}
                  style={
                    iconsStyles
                      ? iconsStyles
                      : { fontSize: NAVIGATION_ICON_DEFAULT.SLIDER_ICONS_SIZE }
                  }
                />
              ) : null}
            </span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target={`#blockContent${nodeToShow.id}`}
            data-bs-slide="next"
          >
            <span className={`${sliderElementsBorderStyle}`} aria-hidden="true">
              {nodeToShow.isShowSliderIcons ? (
                <i
                  className={`bi ${nodeToShow.sliderRightIcon}`}
                  style={
                    iconsStyles
                      ? iconsStyles
                      : { fontSize: NAVIGATION_ICON_DEFAULT.SLIDER_ICONS_SIZE }
                  }
                />
              ) : null}
            </span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <CustomStyles
        node={nodeToShow}
        isMobileViewDimension={isMobileViewDimension}
      />
    </>
  );
};
