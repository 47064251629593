import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    activeModal: null,
    showUpgradeModal: false,
    publishModal: {
      isActive: false,
      pageId: null,
    },
  },
  reducers: {
    openModal: (state, action) => {
      state.activeModal = action.payload.modalName;
    },
    closeModal: (state) => {
      state.activeModal = null;
    },
    setShowUpgradeModal: (state, action) => {
      state.showUpgradeModal = action.payload;
    },
    setPublishModal: (state, action) => {
      state.publishModal = {
        isActive: action.payload.isActive,
        pageId: action.payload.pageId,
      };
    },
  },
});

export const { openModal, closeModal, setShowUpgradeModal, setPublishModal } =
  modalSlice.actions;
export default modalSlice.reducer;
