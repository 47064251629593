import React from 'react'
import {ImageNode} from "../../../types/PageNode";
import imagePlug from "../../../assets/icons/plugs/image-plug.png"

interface ImageBlockProps {
    styleObject: CustomStyle,
    nodeToShow: ImageNode,
    classString: string,
    handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    handleDoubleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    isMobileViewDimension: boolean
}

const ImageBlock: React.FC<ImageBlockProps> = ({
                                                   styleObject,
                                                   nodeToShow,
                                                   classString,
                                                   handleClick,
                                                   handleDoubleClick
                                               }) => {
    let classesPCString
    let classesMobileString
    if (nodeToShow.isMobileStyleImage) {
        classesPCString = [...classString.split(' '), 'd-none', 'd-sm-block'].join(' ')
        classesMobileString = [...classString.split(' '), 'd-block', 'd-sm-none'].join(' ')
    } else {
        classesPCString = [...classString.split(' '), 'd-block'].join(' ')
        classesMobileString = [...classString.split(' '), 'd-none'].join(' ')
    }


    return (<>
        <img
            id={'blockContent' + nodeToShow.id}
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
            src={nodeToShow.src !== null && nodeToShow.src !== undefined ? nodeToShow.src : imagePlug}
            alt={nodeToShow.alt !== undefined ? nodeToShow.alt : ''}
            loading={nodeToShow.loading !== undefined ? nodeToShow.loading : undefined}
            className={classesPCString}
            style={styleObject}
        />
        {nodeToShow.isMobileStyleImage && (
            <img
                id={'blockContent' + nodeToShow.id}
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
                src={nodeToShow.src !== null && nodeToShow.src !== undefined ? nodeToShow.srcMobile : imagePlug}
                alt={nodeToShow.altMobile !== undefined ? nodeToShow.altMobile : ''}
                loading={nodeToShow.loadingMobile !== undefined ? nodeToShow.loadingMobile : undefined}
                className={classesMobileString}
                style={styleObject}
            />
        )}
    </>)
}

export default ImageBlock
