import React, { BaseSyntheticEvent, FC } from "react";
import laptop from "../../../assets/images/laptop.webp"

type Props = {
  title: string;
  description: string;
  confirmText: string;
  show: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
};

export const UpgradePlanModal: FC<Props> = ({
  title,
  description,
  confirmText,
  show,
  onConfirm,
  onCancel,
}) => {
  const closeByBackground = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    if (e.target.id === "confirmModal") onCancel();
  };
  return (
    <div
      id="confirmModal"
      className={`modal ${
        show ? "show d-flex" : ""
      } align-items-center justify-content-center`}
      style={{
        display: show ? "block" : "none",
        background: "rgb(0, 0, 0, 0.6)",
      }}
      onClick={(e) => closeByBackground(e)}
    >
      <div className="col-11" style={{maxWidth: "500px"}}>
        <div className="modal-content text-break d-flex flex-column align-items-center">
          <div className="col-12 modal-header pb-2 border-0">
            <button
              className="btn-close"
              aria-label="Close"
              onClick={onCancel}
              style={{ height: "16px", width: "16px", opacity: "1" }}
            ></button>
          </div>
          <div className="border-0 text-center d-flex flex-column align-items-center px-5">
            <h5 className="col-9 col-md-8 fw-700 fs-4 text-dark-gray mb-3">{title}</h5>
            <img className="mb-3" src={laptop} alt="laptop" />
            <p className="description-responsive text-light-gray" style={{marginBottom: "28px"}}>{description}</p>
          </div>
          <div className="col-12 px-4 pb-4 text-center">
              <button
                type="button"
                className="btn btn-primary text-white fw-bold col-12 py-3 mb-3"
                onClick={onConfirm}
              >
                {confirmText}
              </button>
              <p onClick={onCancel} className="d-inline text-decoration-underline m-0 cursor-pointer">Cancel</p>
          </div>
        </div>
      </div>
    </div>
  );
};
