import { NodeType, PageNode } from "../../../types/PageNode";
import { getNewNode, getNodeWithNewIds } from "../../NodeService";
import { CustomStyleField } from "../../../types/CustomStyleField";
import { SliderDotTypeEnum } from "../../../types/CustomElement";
import { NAVIGATION_ICON_DEFAULT } from "../../../data/constants";

export default class DivSliderNodeFactory {
  private static readonly containerStyles = {
    [CustomStyleField.Height]: "200px",
    [CustomStyleField.BackgroundColor]:
      "linear-gradient(90deg, rgba(178,205,255,1) 0%, rgba(199,224,255,0.97) 48%, rgba(225,239,255,1) 100%)",
  };

  private static readonly backClassesIndexed = {
    "gx-0": "gx-0",
    dFlex: "d-flex",
    horizontalAlignment: "justify-content-center",
    verticalAlignment: "align-items-center",
  };

  private static readonly setSliderText = (text: string) => `
    <p style="font-size: 32px; color: rgb(58, 131, 246)">${text}</p>
  `;

  private static createContainerNode(parentNode: PageNode): PageNode {
    return getNewNode(NodeType.DivNode, parentNode, {
      tagName: "div",
      className: "container-slide",
      backClassesIndexed: this.backClassesIndexed,
      breadCrumbTitle: "slide",
      customStyle: this.containerStyles,
    });
  }

  private static createTextNode(
    container: PageNode,
    sliderText: string
  ): PageNode {
    return getNewNode(NodeType.TextNode, container, {
      tagName: "text",
      className: "",
      value: this.setSliderText(sliderText),
      disallowedAddElements: true,
    });
  }

  public static getSliderNode = (parentNode: PageNode): PageNode => {
    const sliderNode: PageNode = getNewNode(NodeType.DivNode, parentNode, {
      tagName: "div",
      className: "slider",
      type: NodeType.SliderNode,
      sliderLeftIcon: "bi-chevron-left",
      sliderRightIcon: "bi-chevron-right",
      isShowSliderIcons: true,
      isShowSliderDots: true,
      sliderDotType: SliderDotTypeEnum.Square,
      isAutoscroll: true,
      autoscrollTime: 3,
      iconStyles: {
        [CustomStyleField.Color]:
          NAVIGATION_ICON_DEFAULT.SLIDER_ICONS_COLOR as string,
        [CustomStyleField.FontSize]:
          NAVIGATION_ICON_DEFAULT.SLIDER_ICONS_SIZE as number,
      },
      indicatorStyles: {
        [CustomStyleField.Color]:
          NAVIGATION_ICON_DEFAULT.SLIDER_INDICATOR_COLOR as string,
        [CustomStyleField.FontSize]:
          NAVIGATION_ICON_DEFAULT.SLIDER_INDICATOR_SIZE_SQUARE as number,
      },
    });

    const container = this.createContainerNode(sliderNode);
    const text = this.createTextNode(container, "First Slide");

    container.nodes.push(text);
    sliderNode.nodes.push(container);

    return getNodeWithNewIds(sliderNode);
  };

  public static getNewSlideNode = (parentNode: PageNode): PageNode => {
    const container = this.createContainerNode(parentNode);
    const text = this.createTextNode(container, "New Slide");

    container.nodes.push(text);
    return getNodeWithNewIds(container);
  };
}
