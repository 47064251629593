/* eslint-disable */
import * as Types from '../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PublishPageDocument = gql`
    mutation PublishPage($content: String!, $pageId: Int!) {
  publishPage(content: $content, pageId: $pageId) {
    id
  }
}
    `;
export type PublishPageMutationFn = Apollo.MutationFunction<PublishPageMutation, PublishPageMutationVariables>;

/**
 * __usePublishPageMutation__
 *
 * To run a mutation, you first call `usePublishPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishPageMutation, { data, loading, error }] = usePublishPageMutation({
 *   variables: {
 *      content: // value for 'content'
 *      pageId: // value for 'pageId'
 *   },
 * });
 */
export function usePublishPageMutation(baseOptions?: Apollo.MutationHookOptions<PublishPageMutation, PublishPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishPageMutation, PublishPageMutationVariables>(PublishPageDocument, options);
      }
export type PublishPageMutationHookResult = ReturnType<typeof usePublishPageMutation>;
export type PublishPageMutationResult = Apollo.MutationResult<PublishPageMutation>;
export type PublishPageMutationOptions = Apollo.BaseMutationOptions<PublishPageMutation, PublishPageMutationVariables>;
export type PublishPageMutationVariables = Types.Exact<{
  content: Types.Scalars['String']['input'];
  pageId: Types.Scalars['Int']['input'];
}>;


export type PublishPageMutation = { __typename?: 'Mutation', publishPage: { __typename?: 'GraphPage', id?: number | null } };
