import React from "react";
import { NAVIGATION_ICON_DEFAULT } from "../../../../data/constants";
import { ChangeNodeFunction } from "../../../../service/editModalService";
import { SliderDotTypeEnum } from "../../../../types/CustomElement";
import { CustomStyleField } from "../../../../types/CustomStyleField";
import { PageNode } from "../../../../types/PageNode";
import SliderDotsIcons from "./svg/SliderDotsIcons";
import SliderSquareIcons from "./svg/SliderSquareIcons";

interface ISliderIndicatorsProps {
  changeNodeFunction: ChangeNodeFunction;
  editableNode: PageNode;
}

interface IIndicatorIconButtonProps extends ISliderIndicatorsProps {
  indicator: (typeof INDICATORS_SLIDER)[0];
}

const INDICATORS_SLIDER = [
  { icon: <SliderSquareIcons />, dotType: SliderDotTypeEnum.Square },
  { icon: <SliderDotsIcons />, dotType: SliderDotTypeEnum.Dot },
];

const IndicatorIconButton: React.FC<IIndicatorIconButtonProps> = ({
  indicator,
  editableNode,
  changeNodeFunction,
}) => {
  return (
    <div className="col-6">
      <button
        className={`icon-settings-btn btn btn-outline-primary d-flex align-items-center justify-content-center w-100${
          editableNode.sliderDotType === indicator.dotType
            ? " bg-secondary-light"
            : " border-border-gray text-border-gray"
        }`}
        style={{ minHeight: "43px" }}
        type="button"
        onClick={() =>
          changeNodeFunction({
            ...editableNode,
            sliderDotType: indicator.dotType,
            indicatorStyles: {
              ...editableNode.indicatorStyles,
              [CustomStyleField.FontSize]: (indicator.dotType ===
              SliderDotTypeEnum.Dot
                ? NAVIGATION_ICON_DEFAULT.SLIDER_INDICATOR_SIZE_DOT
                : NAVIGATION_ICON_DEFAULT.SLIDER_INDICATOR_SIZE_SQUARE) as number,
            },
          })
        }
      >
        {indicator.icon}
      </button>
    </div>
  );
};

const SliderIndicators: React.FC<ISliderIndicatorsProps> = ({
  editableNode,
  changeNodeFunction,
}) => {
  return (
    <>
      <div className="form-check form-switch my-3">
        <input
          className="form-check-input fs-8"
          type="checkbox"
          role="switch"
          id="isShowSliderDotsSwitch"
          checked={editableNode.isShowSliderDots}
          onChange={() =>
            changeNodeFunction({
              ...editableNode,
              isShowSliderDots: !editableNode.isShowSliderDots,
            })
          }
        />
        <label
          className="form-check-label fw-bold fs-8"
          htmlFor="isShowSliderDotsSwitch"
        >
          Indicators
        </label>
      </div>
      <div className="row align-items-center">
        {INDICATORS_SLIDER.map((indicator, index) => (
          <IndicatorIconButton
            key={index}
            indicator={indicator}
            editableNode={editableNode}
            changeNodeFunction={changeNodeFunction}
          />
        ))}
      </div>
    </>
  );
};

export default SliderIndicators;
