/* eslint-disable */
import * as Types from '../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const UpdateTemplateDocument = gql`
    mutation UpdateTemplate($id: Int!, $content: String!, $img: String!) {
  updateTemplate(id: $id, content: $content, img: $img) {
    content
    img
  }
}
    `;
export type UpdateTemplateMutationFn = Apollo.MutationFunction<UpdateTemplateMutation, UpdateTemplateMutationVariables>;

/**
 * __useUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMutation, { data, loading, error }] = useUpdateTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *      img: // value for 'img'
 *   },
 * });
 */
export function useUpdateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTemplateMutation, UpdateTemplateMutationVariables>(UpdateTemplateDocument, options);
      }
export type UpdateTemplateMutationHookResult = ReturnType<typeof useUpdateTemplateMutation>;
export type UpdateTemplateMutationResult = Apollo.MutationResult<UpdateTemplateMutation>;
export type UpdateTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>;
export const TemplateShowDocument = gql`
    query TemplateShow($id: Int!) {
  templateShow(id: $id) {
    id
    type
    name
    templateCategory {
      id
      title
    }
  }
}
    `;

/**
 * __useTemplateShowQuery__
 *
 * To run a query within a React component, call `useTemplateShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateShowQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateShowQuery(baseOptions: Apollo.QueryHookOptions<TemplateShowQuery, TemplateShowQueryVariables> & ({ variables: TemplateShowQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateShowQuery, TemplateShowQueryVariables>(TemplateShowDocument, options);
      }
export function useTemplateShowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateShowQuery, TemplateShowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateShowQuery, TemplateShowQueryVariables>(TemplateShowDocument, options);
        }
export function useTemplateShowSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemplateShowQuery, TemplateShowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplateShowQuery, TemplateShowQueryVariables>(TemplateShowDocument, options);
        }
export type TemplateShowQueryHookResult = ReturnType<typeof useTemplateShowQuery>;
export type TemplateShowLazyQueryHookResult = ReturnType<typeof useTemplateShowLazyQuery>;
export type TemplateShowSuspenseQueryHookResult = ReturnType<typeof useTemplateShowSuspenseQuery>;
export type TemplateShowQueryResult = Apollo.QueryResult<TemplateShowQuery, TemplateShowQueryVariables>;
export const CommonTemplateCategoryListDocument = gql`
    query CommonTemplateCategoryList {
  commonTemplateCategoryList {
    id
    title
    templatesSortedByPosition {
      id
      content
      isCommon
      img
      type
    }
  }
}
    `;

/**
 * __useCommonTemplateCategoryListQuery__
 *
 * To run a query within a React component, call `useCommonTemplateCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommonTemplateCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommonTemplateCategoryListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommonTemplateCategoryListQuery(baseOptions?: Apollo.QueryHookOptions<CommonTemplateCategoryListQuery, CommonTemplateCategoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommonTemplateCategoryListQuery, CommonTemplateCategoryListQueryVariables>(CommonTemplateCategoryListDocument, options);
      }
export function useCommonTemplateCategoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommonTemplateCategoryListQuery, CommonTemplateCategoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommonTemplateCategoryListQuery, CommonTemplateCategoryListQueryVariables>(CommonTemplateCategoryListDocument, options);
        }
export function useCommonTemplateCategoryListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CommonTemplateCategoryListQuery, CommonTemplateCategoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CommonTemplateCategoryListQuery, CommonTemplateCategoryListQueryVariables>(CommonTemplateCategoryListDocument, options);
        }
export type CommonTemplateCategoryListQueryHookResult = ReturnType<typeof useCommonTemplateCategoryListQuery>;
export type CommonTemplateCategoryListLazyQueryHookResult = ReturnType<typeof useCommonTemplateCategoryListLazyQuery>;
export type CommonTemplateCategoryListSuspenseQueryHookResult = ReturnType<typeof useCommonTemplateCategoryListSuspenseQuery>;
export type CommonTemplateCategoryListQueryResult = Apollo.QueryResult<CommonTemplateCategoryListQuery, CommonTemplateCategoryListQueryVariables>;
export type UpdateTemplateMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  content: Types.Scalars['String']['input'];
  img: Types.Scalars['String']['input'];
}>;


export type UpdateTemplateMutation = { __typename?: 'Mutation', updateTemplate: { __typename?: 'GraphTemplate', content?: string | null, img?: string | null } };

export type TemplateShowQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type TemplateShowQuery = { __typename?: 'Query', templateShow: { __typename?: 'GraphTemplate', id?: number | null, type?: number | null, name?: string | null, templateCategory?: { __typename?: 'GraphTemplateCategory', id?: number | null, title?: string | null } | null } };

export type CommonTemplateCategoryListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CommonTemplateCategoryListQuery = { __typename?: 'Query', commonTemplateCategoryList: Array<{ __typename?: 'GraphTemplateCategory', id?: number | null, title?: string | null, templatesSortedByPosition: Array<{ __typename?: 'GraphTemplate', id?: number | null, content?: string | null, isCommon?: boolean | null, img?: string | null, type?: number | null }> }> };
