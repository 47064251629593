import React from "react";
import { NAVIGATION_ICON_DEFAULT } from "../../../../data/constants";
import { ChangeNodeFunction } from "../../../../service/editModalService";
import { CustomStyleField } from "../../../../types/CustomStyleField";
import { PageNode } from "../../../../types/PageNode";

export interface IIconsSizeProps {
  labelName: string;
  editableNode: PageNode;
  changeNodeFunction: ChangeNodeFunction;
  iconStyleSizeOption: string;
  defaultValue: NAVIGATION_ICON_DEFAULT;
}

export const IconsSize: React.FC<IIconsSizeProps> = ({
  labelName,
  editableNode,
  changeNodeFunction,
  iconStyleSizeOption,
  defaultValue,
}) => {
  return (
    <div className={`position-relative text-background-mobile form-floating`}>
      <p className="position-absolute end-0 top-50 translate-middle-y text-background-mobile me-4 pe-2">
        <small>px</small>
      </p>
      <input
        id={iconStyleSizeOption}
        min="0"
        type="number"
        placeholder={labelName}
        className="form-control"
        value={
          editableNode?.[iconStyleSizeOption as keyof PageNode]?.[
            CustomStyleField.FontSize
          ] || ""
        }
        onChange={(e) => {
          const value = e.target.value;
          changeNodeFunction({
            ...editableNode,
            [iconStyleSizeOption]: {
              ...editableNode[iconStyleSizeOption as keyof PageNode],
              [CustomStyleField.FontSize]: value === "" ? 0 : Number(value),
            },
          });
        }}
        onBlur={(e) => {
          const value = e.target.value;
          if (value === "") {
            changeNodeFunction({
              ...editableNode,
              [iconStyleSizeOption]: {
                ...editableNode[iconStyleSizeOption as keyof PageNode],
                [CustomStyleField.FontSize]: defaultValue,
              },
            });
          }
        }}
      />
      <label htmlFor={iconStyleSizeOption} className={`form-label`}>
        {labelName}
      </label>
    </div>
  );
};

export default IconsSize;
