import React from "react"
const AddSlideIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
  >
    <path
      fill="currentColor"
      d="M4.423 17.583c-.421 0-.777-.146-1.069-.437a1.454 1.454 0 0 1-.438-1.07V4.924c0-.421.146-.777.438-1.069a1.454 1.454 0 0 1 1.069-.438h6.25v1.25h-6.25a.25.25 0 0 0-.184.073.25.25 0 0 0-.072.184v11.154a.25.25 0 0 0 .072.184.25.25 0 0 0 .184.072h11.154a.25.25 0 0 0 .184-.072.25.25 0 0 0 .072-.184v-6.25h1.25v6.25c0 .42-.146.777-.437 1.069a1.455 1.455 0 0 1-1.07.437H4.424Zm.897-3.173h9.36l-2.91-3.878-2.5 3.245-1.77-2.252-2.18 2.885ZM14.166 8V6.333H12.5v-1.25h1.666V3.416h1.25v1.667h1.667v1.25h-1.666V8h-1.25Z"
    />
  </svg>
)
export default AddSlideIcon
