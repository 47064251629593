import React, { SetStateAction, useEffect } from "react";
import ColorPicker from "react-best-gradient-color-picker";
import { NAVIGATION_ICON_DEFAULT } from "../../../../data/constants";
import { ChangeNodeFunction } from "../../../../service/editModalService";
import { CustomStyleField } from "../../../../types/CustomStyleField";
import { PageNode } from "../../../../types/PageNode";

interface ISelectIconsColorProps {
  labelName: string;
  editableNode: PageNode;
  changeNodeFunction: ChangeNodeFunction;
  iconStyleColorOption: string;
  defaultValue: NAVIGATION_ICON_DEFAULT;
  showBlock: string;
  setShowBLock: React.Dispatch<SetStateAction<string>>;
}

const SelectIconsColor: React.FC<ISelectIconsColorProps> = ({
  labelName,
  editableNode,
  changeNodeFunction,
  iconStyleColorOption,
  defaultValue,
  showBlock,
  setShowBLock,
}) => {
  const handleChangeColor = (color: string): void => {
    changeNodeFunction({
      ...editableNode,
      [iconStyleColorOption]: {
        ...editableNode[iconStyleColorOption as keyof PageNode],
        [CustomStyleField.Color]: color,
      },
    });
  };

  const deleteColor = () => {
    changeNodeFunction({
      ...editableNode,
      [iconStyleColorOption]: {
        ...editableNode[iconStyleColorOption as keyof PageNode],
        [CustomStyleField.Color]: defaultValue,
      },
    });
  };

  useEffect(() => {
    let isMouseDownInside = false;

    const handleMouseDown = (event: any): void => {
      isMouseDownInside = !!event.target.closest(".color-picker-input");
    };

    const handleMouseUp = (event: any): void => {
      if (
        showBlock === iconStyleColorOption &&
        !event.target.closest(".color-picker-input") &&
        !isMouseDownInside
      ) {
        setShowBLock("");
      }
      isMouseDownInside = false;
    };

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [setShowBLock, showBlock, iconStyleColorOption]);

  return (
    <>
      <div className="form-floating mb-md-0 mb-3">
        <div className="input-group">
          <div className="form-floating position-relative">
            <input
              className="form-control"
              id={iconStyleColorOption}
              placeholder={labelName}
              type="text"
              value={
                editableNode?.[iconStyleColorOption as keyof PageNode]?.[CustomStyleField.Color] || defaultValue
              }
              readOnly
              onClick={() => {
                setShowBLock(iconStyleColorOption);
              }}
            />
            <label htmlFor={iconStyleColorOption} className="form-label">
              {labelName}
            </label>
            <div
              className="position-absolute rounded-1 p-3 border top-50 end-0 translate-middle-y me-3 pe-none"
              style={{
                background:
                  editableNode?.[iconStyleColorOption as keyof PageNode]
                    ?.[CustomStyleField.Color] || defaultValue,
              }}
            ></div>
          </div>
          <button
            className="btn btn-trash btn-outline-secondary px-4"
            type="button"
            onClick={deleteColor}
          >
            <i className="bi bi-trash icon-size"></i>
          </button>
        </div>

        {showBlock === iconStyleColorOption ? (
          <div className="position-absolute translate-middle-x start-50 bg-white p-4 color-picker-input border rounded shadow">
            <div className="d-flex justify-content-between">
              <div className="mb-3">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    deleteColor();
                    setShowBLock("");
                  }}
                >
                  Clear
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-close pt-3"
                  onClick={() => setShowBLock("")}
                ></button>
              </div>
            </div>
            <ColorPicker
              className={"tmp"}
              value={
                editableNode?.[iconStyleColorOption as keyof PageNode]?.[CustomStyleField.Color] ||
                defaultValue
              }
              onChange={handleChangeColor}
            />
            <div className="text-end">
              <button
                type="button"
                className="btn btn-primary w-100 mt-3"
                onClick={() => setShowBLock("")}
              >
                Select
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SelectIconsColor;
