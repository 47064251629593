import React from "react";
import { PageNode } from "../../../../types/PageNode";
import { ISliderIconProps } from "./SliderIcon";
import VerticalLineIcon from "./svg/VerticalLineIcon";

interface IDoubleIconButtonProps extends ISliderIconProps {
  icons: string[];
  property1: string;
  property2: string;
}

export const DoubleIconButton: React.FC<IDoubleIconButtonProps> = ({
  icons,
  editableNode,
  changeNodeFunction,
  property1,
  property2,
}) => {
  return (
    <button
      className={`icon-settings-btn btn btn-outline-primary w-100 d-flex align-items-center justify-content-center gap-3${
        editableNode[property1 as keyof PageNode] !== icons[0] ||
        editableNode[property2 as keyof PageNode] !== icons[1]
          ? " border-border-gray text-border-gray"
          : " bg-secondary-light"
      }`}
      type="button"
      onClick={() =>
        changeNodeFunction({
          ...editableNode,
          [property1 as keyof PageNode]: icons[0],
          [property2 as keyof PageNode]: icons[1],
        })
      }
    >
      <i
        className={`bi ${icons[0]}${
          editableNode[property1 as keyof PageNode] !== icons[0] ||
          editableNode[property2 as keyof PageNode] !== icons[1]
            ? " text-dark"
            : ""
        }`}
      ></i>{" "}
      <VerticalLineIcon />{" "}
      <i
        className={`bi ${icons[1]}${
          editableNode[property2 as keyof PageNode] !== icons[1] ||
          editableNode[property1 as keyof PageNode] !== icons[0]
            ? " text-dark"
            : ""
        }`}
      ></i>
    </button>
  );
};

export default DoubleIconButton;
