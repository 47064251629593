import React from "react"
const DuplicateAccordionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
  >
    <path
      fill="currentColor"
      d="M4.644 17.938c-.442 0-.816-.154-1.122-.46a1.527 1.527 0 0 1-.46-1.122v-3.231c0-.442.154-.816.46-1.122.306-.307.68-.46 1.122-.46h11.712c.391 0 .729.127 1.013.38.284.253.448.564.493.933H4.644a.262.262 0 0 0-.193.075.262.262 0 0 0-.076.194v3.23c0 .08.025.144.076.194.05.05.115.076.193.076h8.212v1.313H4.644Zm0-8.473c-.442 0-.816-.153-1.122-.46a1.527 1.527 0 0 1-.46-1.122V4.644c0-.442.154-.816.46-1.122.306-.306.68-.46 1.122-.46h11.712c.442 0 .816.154 1.122.46.306.306.46.68.46 1.122v3.24c0 .441-.154.815-.46 1.122-.306.306-.68.46-1.122.46H4.644Zm0-1.312h11.712a.262.262 0 0 0 .193-.076.262.262 0 0 0 .076-.194V4.644a.262.262 0 0 0-.076-.193.262.262 0 0 0-.193-.076H4.644a.262.262 0 0 0-.193.076.262.262 0 0 0-.076.193v3.24c0 .078.025.142.076.193.05.05.115.076.193.076Zm11.981 11.635v-1.85h-1.851v-1.313h1.851v-1.851h1.313v1.851h1.85v1.313h-1.85v1.85h-1.313Z"
    />
  </svg>
)
export default DuplicateAccordionIcon
