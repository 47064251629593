import React, { ChangeEvent } from "react";
import {
  ChangeNodeFunction,
} from "../../../../service/editModalService";
import { PageNode } from "../../../../types/PageNode";
interface IBehaviorSelectProps {
  editableNode: PageNode;
  changeNodeFunction: ChangeNodeFunction;
}

const BehaviorSelect: React.FC<IBehaviorSelectProps> = ({
  editableNode,
  changeNodeFunction,
}) => {

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    changeNodeFunction({...editableNode, expanded: event.target.value === "open"});
  }
  return (
    <>
        <div className="fw-bold fs-8 mb-2">Section Behavior</div>
        <div className="form-floating small">
            <select className="form-select border-border-gray" onChange={handleChange} value={editableNode.expanded ? "open" : "close"}>
              <option value={"open"}>Open by default</option>
              <option value={"close"}>Close by default</option>
            </select>
            <label htmlFor="edit-block-modal-font-size" className="form-label">Section behavior</label>
        </div>
    </>
  );
};

export default BehaviorSelect;
