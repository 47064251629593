import React from "react";
import { ChangeNodeFunction } from "../../../../service/editModalService";
import { PageNode } from "../../../../types/PageNode";
import DoubleIconButton from "./DoubleIconButton";

export interface ISliderIconProps {
  changeNodeFunction: ChangeNodeFunction;
  editableNode: PageNode;
}

const ICONS_SLIDER = [
  ["bi-chevron-left", "bi-chevron-right"],
  ["bi-caret-left", "bi-caret-right"],
  ["bi-caret-left-fill", "bi-caret-right-fill"],
];

const SliderIcon: React.FC<ISliderIconProps> = ({
  editableNode,
  changeNodeFunction,
}) => {
  return (
    <>
      <div className="form-check form-switch my-3 ">
        <input
          className="form-check-input fs-8"
          type="checkbox"
          role="switch"
          id="isShowSliderIconsSwitch"
          checked={editableNode.isShowSliderIcons}
          onChange={() =>
            changeNodeFunction({
              ...editableNode,
              isShowSliderIcons: !editableNode.isShowSliderIcons,
            })
          }
        />
        <label
          className="form-check-label fw-bold fs-8"
          htmlFor="isShowSliderIconsSwitch"
        >
          Slider Icon
        </label>
      </div>
      <div className="row g-3">
        {ICONS_SLIDER.map((icons, index) => (
          <div className="col-6 col-lg-4" key={index}>
            <DoubleIconButton
              icons={icons}
              property1="sliderLeftIcon"
              property2="sliderRightIcon"
              editableNode={editableNode}
              changeNodeFunction={changeNodeFunction}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default SliderIcon;
