import React from "react";
import { PageNode } from "../../../../types/PageNode";
import {
  ChangeNodeFunction,
} from "../../../../service/editModalService";
import BehaviorSelect from "./BehaviorSelect";
import IconPlaceSelect from "./IconPlaceSelect";
import AccordionIcon from "./AccordionIcon";

interface IAccordionSettingsTabProps {
  editableNode: PageNode;
  changeNodeFunction: ChangeNodeFunction;
}

const AccordionSettingsTab: React.FC<IAccordionSettingsTabProps> = ({
  editableNode,
  changeNodeFunction,
}) => {
  return (
    <>
      <div className="col-lg-6">
        <BehaviorSelect editableNode={editableNode} changeNodeFunction={changeNodeFunction} />
      </div>
      <div className="mt-4">
        <AccordionIcon editableNode={editableNode} changeNodeFunction={changeNodeFunction} />
      </div>
      <div className="col-lg-6 mt-4">
        <IconPlaceSelect editableNode={editableNode} changeNodeFunction={changeNodeFunction} />
      </div>
    </>
  );
};

export default AccordionSettingsTab;
