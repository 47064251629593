import React from "react";
import { ChangeNodeFunction } from "../../../../service/editModalService";
import { PageNode } from "../../../../types/PageNode";
import SliderAutoscroll from "./SliderAutoscroll";
import SliderIcon from "./SliderIcon";
import SliderIndicators from "./SliderIndicators";

interface ISliderSettingsProps {
  changeNodeFunction: ChangeNodeFunction;
  editableNode: PageNode;
}

const SliderSettingsTab: React.FC<ISliderSettingsProps> = ({
  changeNodeFunction,
  editableNode,
}) => {
  return (
    <div className="row">
      <div className="col-lg-6">
        <SliderIcon
          editableNode={editableNode}
          changeNodeFunction={changeNodeFunction}
        />
      </div>
      <div className="col-lg-6">
        <SliderIndicators
          editableNode={editableNode}
          changeNodeFunction={changeNodeFunction}
        />
      </div>
      <div className="col">
        <SliderAutoscroll editableNode={editableNode}
          changeNodeFunction={changeNodeFunction} />
      </div>
    </div>
  );
};

export default SliderSettingsTab;
