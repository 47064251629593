import {IconStyles, PageNode} from "../types/PageNode";
import React from "react";
import {changeActiveModalNode} from "../reducers/node-modal";
import {Modal} from "bootstrap";
import {findNodeWhichAreContainer} from "./EditBarService";
import {changeActiveContainerNode} from "../reducers/active-container-node";

export class NodeRenderService {
    public static getBolderStyle(node: PageNode, currentActiveNode: PageNode, activeContainerNode: PageNode, isPreviewState: boolean, isAdvancedMode: boolean) {
        let borderStyle = '';
        if (!isPreviewState && !(node.isHidden && !isAdvancedMode)) {
            if (currentActiveNode && node.id === currentActiveNode.id) {
                borderStyle = 'solid-blue'
            } else if (activeContainerNode && node.id === activeContainerNode.id) {
                borderStyle = 'dashed-blue'
            } else {
                if (node.className !== 'row') {
                    borderStyle = 'dashed-grey'
                }
            }
        }
        return borderStyle;
    }

    public static getStyle(nodeToShow: PageNode) {
        if (nodeToShow.style === undefined) {
            nodeToShow.style = {};
        }
        if (nodeToShow.background) {
            nodeToShow.style = {...nodeToShow.style, ...{background: nodeToShow.background}}
        }
        return {...nodeToShow.style, ...nodeToShow.customStyle};
    }

    public static getClasses(nodeToShow: PageNode, currentActiveNode: PageNode, activeContainerNode: PageNode, isPreviewState: boolean, isAdvancedMode: boolean) {
        let classes = []
        const borderStyle = NodeRenderService.getBolderStyle(nodeToShow, currentActiveNode, activeContainerNode, isPreviewState, isAdvancedMode);

        if (nodeToShow.classes) {
            nodeToShow.classes.forEach(function (element) {
                classes.push(element)
            });
        }

        if (nodeToShow.backClassesMobile && nodeToShow?.isMobileStyleContainerSize) {
            Object.entries(nodeToShow.backClassesMobile).forEach(([key, value]) => {
                classes.push(value)
            })
        } 
        if (nodeToShow.backClassesIndexed) {
            Object.entries(nodeToShow.backClassesIndexed).forEach(([, value]) => {
                classes.push(nodeToShow?.isMobileStyleContainerSize ? value : value.replace('-md', ''))
            })

        }

        if (nodeToShow.className) {
            classes.push(nodeToShow.className);
        }
        if (borderStyle) {
            classes.push(borderStyle)
        }

        return classes
    }

    public static getInnerValue(nodeToShow: PageNode, dispatch: any) {
        return <div
            className='text-center text-background-mobile fs-7 d-flex align-items-center h-100 justify-content-center'>
            <span onClick={(e) => {
                e.stopPropagation();
                dispatch(changeActiveModalNode(nodeToShow));
                Modal.getOrCreateInstance('#container-to-add-modal').show();
            }}><i className='bi bi-plus-lg'></i>Add Content</span>
        </div>
    }

    public static handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>, isPreviewState: boolean, isAdvancedMode: boolean, nodeToShow: PageNode, dispatch: any, nodes: PageNode[]) {
        if (!isPreviewState) {
            event.preventDefault()
        }
        if (isAdvancedMode || !nodeToShow.isHidden) {
            event.stopPropagation();
            dispatch(changeActiveModalNode(nodeToShow));
            const parentNode = findNodeWhichAreContainer(nodeToShow, nodes, 'row');
            dispatch(changeActiveContainerNode(parentNode));
        }
    }

    public static handleDoubleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        event.stopPropagation();
        Modal.getOrCreateInstance('#edit-block-modal').show();
    }

    public static getIconStyles(styles?: IconStyles) {
        if (!styles) return undefined;
    
        return Object.entries(styles).reduce((acc, [key, value]) => {
          const cssKey = key.replace(/([A-Z])/g, "-$1").toLowerCase();
    
          if (
            cssKey === "color" &&
            typeof value === "string" &&
            value.includes("gradient")
          ) {
            acc["background"] = value;
            acc["WebkitBackgroundClip"] = "text";
            acc["WebkitTextFillColor"] = "transparent";
          } else {
            (acc as Record<string, string | number>)[cssKey] =
              typeof value === "number" ? `${value}px` : value;
          }
    
          return acc;
        }, {} as Record<string, string | number>) as React.CSSProperties;
    };
}